import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class ServiceService {
	public price_types:any = [{
		name: "Monthly"
	}, {
		name: "Annually"
	}, {
		name: "One time"
	}];
	public all_services: any = [];
	public services: any = [];
	public _services: any = {};
	public group: any = {};
	public by_location:any = {};
	public now;
	constructor(private alert: AlertService, private mongo: MongoService) { 
		mongo.get('service', {
			replace:{
				disabled: (val, cb, doc)=>{
					cb(doc.deleted);
				}
			},
			groups: {
				group: {
					allow: doc => !doc.deleted,
					field: function(doc){
						return doc.group;
					}
				},
				location: {
					allow: doc => !doc.deleted,
					field: function(doc, cb){
						if(doc.locations) {
							for (let i = 0; i < doc.locations.length; i++){
							    cb(doc.locations[i]);
								
							}
						}
					}
				}
			},
			query: {
				active: doc => !doc.deleted
			}
		}, (arr, obj) => {
			this.now = Date.now();
			this.services = obj.active;
			this.by_location = obj.location;
			this.all_services = arr;
			this.group = obj.group;
			this._services = obj;
		});
	}
	create(service, cb: any = (created) => {}) {
		if(service._id) return this.update(service, cb);
		this.mongo.create('service', service, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Service has been created.'
			});
			this.now = Date.now();
		}); 
	}
	update(service, cb: any = () => {}) {
		this.mongo.afterWhile(service, ()=> {
			this.mongo.update('service', service, (updated) => {
				if(typeof cb === 'function') cb(updated);
				this.now = Date.now();
			});
		});
	}
	delete(service, cb: any = () => {}) {
		service.deleted = true;
		this.mongo.afterWhile(service, ()=> {
			this.mongo.update('service', service, (updated) => {
				if(typeof cb === 'function') cb(updated);
				this.now = Date.now();
			});
		});
	}
	refresh(serviceId){
		this._services[serviceId] = this.mongo.fetch('service', {
			query: {
				_id: serviceId
			},
			force: true
		});
		return this._services[serviceId];
	}
}
