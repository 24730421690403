<div class="containerBox setup">
    <div class="setup-empty setup-box" *ngIf="!qs.questions?.length">
        <div class="setup-empty__img"><img src="assets/img/setup/question.png" (load)="allImagesLoaded.next(true)" alt="question" draggable="false"/></div>
        <div class="setup-empty__title">A well-crafted questionnaire helps you gather essential information from your clients</div>
        <div class="setup-empty__btn">
            <button mat-flat-button color="primary" class="waw-btn _primary" (click)="openQuestion()">
				<span class="icon-plus"></span>
				<span>Add question</span>
			</button>
        </div>
    </div>
    <ng-container *ngIf="qs.questions?.length">
        <div class="jc-e mb20">
            <button mat-flat-button color="primary" class="waw-btn _primary" (click)="openQuestion()">
				<span class="icon-plus"></span>
				<span>Add question</span>
			</button>
        </div>

        <div class="qst-container">
            <!-- left column -->
            <div class="qst-list _left">
                <ng-container *ngFor="let question of qs.questions; index as i">
                    <ng-container *ngIf="(i + 1) % 2 == 1">
                        <ng-container *ngTemplateOutlet="item; context: {$implicit: question}"></ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <!-- right column -->
            <div class="qst-list _right">
                <ng-container *ngFor="let question of qs.questions; index as i">
                    <ng-container *ngIf="(i + 1) % 2 == 0">
                        <ng-container *ngTemplateOutlet="item; context: {$implicit: question}"></ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <!-- all -->
            <div class="qst-list _all">
                <ng-container *ngFor="let question of qs.questions;">
                    <ng-container *ngTemplateOutlet="item; context: {$implicit: question}"></ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #item let-question>
    <div class="qst-item">
        <div class="qst-item__title">{{question.title}}</div>
        <ol *ngIf="question.kind === 'select' || question.kind === 'multi'" class="qst-item__body">
            <li *ngFor="let opt of question.options" class="qst-item__body__option">
                <span>{{opt.answer}}</span>
            </li>
        </ol>

        <div class="qst-item__btn">
            <button mat-icon-button color="primary" *ngIf="us.is.admin || us.is.owner" (click)="edit(question);" class="waw-btn _second _edit"></button>
        </div>
    </div>
</ng-template>