<div class="setup-empty setup-box" *ngIf="!rs.rooms?.length">
    <div class="setup-empty__img"><img src="assets/img/setup/closed-date-preview.png" (load)="allImagesLoaded.next(true)" alt="rooms" draggable="false"/></div>
    <div class="setup-empty__title">No rooms yet<br><a (click)="edit({location: this.ls._locations.primary[0]?._id||'', availability:{Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: []}})">Click here</a> to add a room now</div>
</div>

<div class="setup-container" *ngIf="rs.rooms?.length">
    <div class="setup-item setup-box" *ngFor="let room of rs.rooms" style="grid-row: span 2;">
        <div class="setup-item__title">{{room.name}}</div>
        <div class="setup-item__actions" *ngIf="us.is.admin||us.is.owner">
            <button mat-icon-button color="primary" class="action-edit" (click)="edit(room);"><span class="icon-edit"></span></button>
            <button mat-icon-button color="warn" class="action-delete" (click)="delete(room);"><span class="icon-delete"></span></button>
        </div>
        <hr>
        <div class="setup-item__property" *ngFor="let day of us.days_of_week" style="align-items: flex-start;">
            <div class="setup-item__property__name">{{ day }}:</div>
            <span class="setup-item__property__value text-elipsis">
                <ng-container *ngFor="let hour of getBusinessHours(room.availability?.[day]); last as last">
                    <span>{{hour}}</span>
                    <wbr *ngIf="!last">
                </ng-container>
            </span>
        </div>
    </div>
</div>