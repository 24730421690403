<div class="setup-empty setup-box" *ngIf="!cs.categories?.length">
    <div class="setup-empty__img"><img src="assets/img/setup/discount.png" (load)="allImagesLoaded.next(true)" alt="categories" draggable="false"/></div>
    <div class="setup-empty__title">No categories yet<br><a (click)="openModal()">Click here</a> to add a category now</div>
</div>

<div class="setup-container" *ngIf="cs.categories?.length">
    <div class="setup-item setup-box" *ngFor="let category of cs.categories">
        <div class="setup-item__title">{{category.name}}</div>
        <div class="setup-item__actions" *ngIf="us.is.admin||us.is.owner">
            <button mat-icon-button color="primary" class="action-edit" (click)="openModal(category);"><span class="icon-edit"></span></button>
            <button mat-icon-button color="warn" class="action-delete" (click)="delete(category);"><span class="icon-delete"></span></button>
        </div>
    </div>
</div>