<div class="containerBox setup">
	<div class="setup-empty setup-box" *ngIf="!ds.discounts?.length">
		<div class="setup-empty__img" style="max-height: 335px;"><img src="assets/img/setup/discount.png" (load)="allImagesLoaded.next(true)" alt="discount" draggable="false"/></div>
		<div class="setup-empty__title">Set up manual discount types for use during checkout.
			You do not have any discount yet, create new discount to apply it on checkout</div>
		<div class="setup-empty__btn">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add discount</span>
			</button>
		</div>
	</div>
	<div class="setup-container" *ngIf="ds.discounts?.length">
		<div class="setup-item setup-box" *ngFor="let discount of ds.discounts">
			<div class="setup-item__title">{{discount.name}}</div>
			<div class="setup-item__actions">
				<button mat-icon-button color="primary" class="action-edit" (click)="edit(discount);"><span class="icon-edit"></span></button>
				<button mat-icon-button color="warn" class="action-delete" (click)="delete(discount);"><span class="icon-delete"></span></button>
			</div>
			<hr>
			<div class="setup-item__property">
				<div class="setup-item__property__name">Value</div>
				<span class="setup-item__property__value text-elipsis">{{discount.amount}}{{discount.kind=='perc'&&'%'||'$'}}</span>
			</div>
			<div class="setup-item__property">
				<div class="setup-item__property__name">Added</div>
				<span class="setup-item__property__value text-elipsis">{{discount._id|mongodate|date:'medium'}}</span>
			</div>
		</div>
		<div class="setup-add">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add discount</span>
			</button>
		</div>
	</div>
</div>
