<div class="containerBox flex-column" hideOnScroll>
	<div class="navigation without_filter" [class.navShown]="!us.sidebarToggle" [class.without_searching]="tab != 'users'">
		<ng-container *ngIf="tab == 'users'">
			<div class="navigation__search search-input">
				<input type="text" placeholder="Search user" name="search" [(ngModel)]="search">
			</div>
		</ng-container>

		<div class="navigation__tab">
			<tab [(value)]="tab" [tabs]="tabs" (valueChange)="afterViewInit ? loader.remove() : ''"></tab>
		</div>

		<div class="navigation__right">
			<div class="navigation__btn">
				<ng-container *ngIf="tab == 'users'">
					<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openCreateuser()">
						<span class="icon-plus"></span>
						<span>{{ us.sidebarToggle ? 'Add new user' : 'New user' }}</span>
					</button>
				</ng-container>
				<ng-container *ngIf="tab != 'users'">
					<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openRole()">
						<span class="icon-plus"></span>
						<span>{{ us.sidebarToggle ? 'Add new role' : 'New role' }}</span>
					</button>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="containerTab fl-grow">

		<!-- USER LIST TAB -->
		<ng-container *ngIf="tab == 'users'">
			<div class="table-container">
				<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
					<thead>
						<tr>
							<th scope="col" class="sort" (click)="sort('name')">
								<div class="ai-c">
									<span>Name</span>
									<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'name'">
										<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
										<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
									</span>
								</div>
							</th>
							<th scope="col" class="sort" (click)="sort('email')">
								<div class="ai-c">
									<span>Email</span>
									<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'email'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'email'">
										<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
										<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
									</span>
								</div>
							</th>
							<th scope="col" *ngIf="rs.roles.length">Role</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let user of us.users|search:search:'name email'|sort:sort_type:us.now; index as i">
							<td data-label="Name" class="ovf-hidden">
								<div class="table__user">
									<div class="table__user__img" [class._letter]="!user.avatarUrl">
										<picture [user]="user"></picture>
									</div>
									<div class="table__user__info">
										<span class="table__user__info-name">{{user.firstName}} <wbr> {{user.lastName}}</span>
										<wbr>
										<small *ngIf="user.deleted" class="table__user__info-deleted"> (Deleted)</small>
									</div>
								</div>
							</td>
							<td data-label="Email" class="text-elipsis">{{user.email}}</td>
							<td data-label="Role" *ngIf="rs.roles.length">
								<div class="select-role">
									<app-select 
										placeholder="Role" 
										[(value)]="user._role"
										(valueChange)="roleChange(user, $event)"
										[items]="allRoles" 
										[clearable]="true" 
										[searchable]="false"
										bindLabel="name" 
										bindValue="_id"
										[disabled]="user.is.owner"
									>
										<ng-template select let-item let-open="open">
											<ng-container *ngIf="item && !user.is.owner">
												<button mat-button color="primary" class="waw-btn _second role-edit">
													<span>{{item?.name}}</span>
													<span class="icon-arrow_down"></span>
												</button>
											</ng-container>
											<ng-container *ngIf="!item && !user.is.owner">
												<button mat-button class="waw-btn role-add">
													<span>No role</span>
													<span class="icon-plus"></span>
												</button>
											</ng-container>
											<ng-container *ngIf="user.is.owner">
												<div class="role-edit">
													Owner
												</div>
											</ng-container>
										</ng-template>
									</app-select>
								</div>
							</td>
							<td>
								<div class="actions">
									<ng-container *ngIf="!user.deleted">
										<button mat-icon-button color="primary" (click)="edit(user);" class="waw-btn__icon primary _mobile" tooltip="Edit" tooltipMobileDisabled="true" aria-label="Edit">
											<span class="icon-pencil"></span>
										</button>
										<ng-container *ngIf="!user.is.owner">
											<button mat-icon-button color="warn" (click)="delete(user);" class="waw-btn__icon warn _mobile" tooltip="Delete" tooltipMobileDisabled="true" aria-label="Delete">
												<span class="icon-delete"></span>
											</button>
										</ng-container>
										<ng-container *ngIf="user.is.owner">
											<button mat-icon-button color="warn" tooltip="Owner cannot be deleted" disableRipple (click)="false" class="waw-btn__icon warn _mobile" aria-label="Delete">
												<span class="icon-delete"></span>
											</button>
										</ng-container>
									</ng-container>
									<ng-container *ngIf="user.deleted">
										<button mat-button color="primary" (click)="return(user);" class="waw-btn _second restore">
											Restore
										</button>
									</ng-container>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</ng-container>


		<!-- PERMISION TAB  -->
		<ng-container *ngIf="tab == 'permission'">
			<div class="permission-container">
				<div class="setup-item setup-box" *ngFor="let module of rs.modules">
					<div class="setup-item__title">
						<span class="setup-item__title-icon" [ngClass]="module.icon"></span>
						<span>{{module.name}}</span>
					</div>
					<ng-container *ngFor="let section of module.sections">
						<div class="setup-item__description">{{section.name}}</div>
						<hr>
						<div class="setup-item__property">
							<label class="setup-item__property-checkbox waw-checkbox">
								<input type="checkbox" [checked]="section.client" disabled>
								<span class="checkmark" [class._client]="!section.client"></span>
								<span class="setup-item__property__name waw-checkbox__text t-hide">Client</span>
							</label>
						</div>
						<div class="setup-item__property" *ngFor="let role of rs.roles">
							<label class="setup-item__property-checkbox waw-checkbox">
								<input type="checkbox" [(ngModel)]="role.config[section.id]" (ngModelChange)="updateRole(role)">
								<span class="checkmark"></span>
								<span class="setup-item__property__name waw-checkbox__text t-hide">{{role.name}}</span>
							</label>
						</div>
						<div class="setup-item__property">
							<label class="setup-item__property-checkbox waw-checkbox">
								<input type="checkbox" checked disabled>
								<span class="checkmark"></span>
								<span class="setup-item__property__name waw-checkbox__text t-hide">Admin & Owner</span>
							</label>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>

		<!-- ROLE TAB  -->
		<ng-container *ngIf="tab == 'role'">
			<div class="setup-container">
				<div class="setup-item setup-box">
					<div class="setup-item__title">Admin</div>
				</div>
				<div class="setup-item setup-box" *ngFor="let role of rs.roles">
					<div class="setup-item__title">{{role.name}}</div>
					<div class="setup-item__actions">
						<button mat-icon-button color="primary" class="action-edit" (click)="openRole(role);"><span class="icon-edit"></span></button>
						<button mat-icon-button color="warn" class="action-delete" (click)="deleteRole(role);"><span class="icon-delete"></span></button>
					</div>
				</div>
			</div>
		</ng-container>

	</div>
</div>
