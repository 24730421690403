import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {
	GroupService,
	ServiceService,
	LocationService,
	UserService,
	RoomService,
	LoaderService
} from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-services',
	templateUrl: './services.component.html',
	styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, AfterViewInit, OnDestroy {
	public group_id = null;
	public allImagesLoaded = new BehaviorSubject <boolean>(false);
	editService(new_service){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'service', new_service: JSON.parse(JSON.stringify(new_service))})
	}
	create(groupId = null){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'service', groupId: groupId});
	}
	constructor(public us: UserService,
		public ls: LocationService,
		public rs: RoomService,
		public gs: GroupService,
		public ss: ServiceService,
		public modal: ModalService,
		private route: ActivatedRoute,
		private router: Router,
		private mongo: MongoService,
		private loader: LoaderService,
		private eref: ElementRef) {
		this.route.paramMap.subscribe(params => {
			this.group_id = params.get('group_id');
			if(!this.group_id) {
				this.us.headerTitle = 'Services';
				this.us.headerSubtitle = '';
			} else {
				this.us.headerTitle = this.gs._groups[this.group_id]?.name || '';
				this.us.headerSubtitle = 'Services';
			}
		});
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
		this.us.backRoute = () => {
			this.router.navigate(['/services']);
		};
	}
	ngAfterViewInit() {
		this.mongo.on('user location room group service', () => {
			if(this.group_id) this.us.headerTitle = this.gs._groups[this.group_id]?.name || '';
			this.removeLoader();
		});
	}
	ngOnDestroy(): void {
		this.us.backRoute = null;
	}
	public sort_type:any = {};
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(profile = null){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'modal',
			profile: profile ? JSON.parse(JSON.stringify(profile)) : {},
			title: 'Service Group',
			components: [
				{
					type: 'INPUT',
					input: 'name',
					label: 'Group name*',
					placeholder: 'Enter group name',
					reqText: 'Name is required',
					required: true,
				}, {
					type: 'EDITOR',
					input: 'description',
					label: 'Notes',
					placeholder: 'Add privates notes',
					size: 'large'
				}
			],
			alertOpts: {
				title: 'Delete group',
				body: `Are you sure you want to delete group ${profile?.name}?`
			},
			onDelete: (profile: any) => {
				this.gs.delete(profile, () => {
					this.modal.destroy();
				});
			},
			onSave: (profile: any) => {
				this.gs.create(profile, () => {
					this.modal.destroy();
				});
			}
		});	
	}	
	delete(group) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete group',
			body: `${this.ss.group[group._id]?.length ? this.ss.group[group._id]?.length+` services in this group will also be deleted. ` : ``}Are you sure you want to delete group ${group.name}?`,
			on_add: ()=> {
				this.router.navigate(['/services']);
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.gs.delete(group, () => {					
					this.removeLoader();
				});
			}
		});
	}
	getLocations(locations) {
		this.mongo.on('location', () => {
			for(let i = 0; i < locations?.length; i++) {
				if(!this.ls._locations[locations[i]]) locations.splice(i, 1);
			}
		});
		return locations;
	}
	getStaff(staff) {
		this.mongo.on('user', () => {
			for(let i = 0; i < staff?.length; i++) {
				if(!this.us._users[staff[i]] || this.us._users[staff[i]].deleted) staff.splice(i, 1);
			}
		});
		return staff;
	}
	removeLoader() {
		if((!this.group_id && !this.gs.groups.length) || (this.group_id && !this.ss.group[this.group_id]?.length)) {
			this.allImagesLoaded.subscribe((state: boolean) => {
				if(state === true) {
					this.loader.remove();
				}
			});
		} else {
			this.loader.remove();
		}
	}
}