<!-- ===  Clients List Table === -->
<div class="table-container tablet-col3 mobile-col3" [class._empty]="!us.staff?.length">
	<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
		<thead>
			<tr>
                <th scope="col" class="sort" (click)="sort('name')">
					<div class="ai-c">
						<span>Staff</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'name'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col" class="sort" (click)="sort('data.address')">
					<div class="ai-c">
						<span>Address</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.address'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.address'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col" class="sort" (click)="sort('email')">
					<div class="ai-c">
						<span>Email</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'email'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'email'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col" class="sort" (click)="sort('phone')">
					<div class="ai-c">
						<span>Phone</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'phone'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'phone'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col" class="sort" (click)="sort('data.birthday.singleDate.epoc')">
					<div class="ai-c">
						<span>Date of Birth</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.birthday.singleDate.epoc'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.birthday.singleDate.epoc'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col">Appoint</th>
                <th scope="col">Notes</th>
            </tr>
        </thead>
        <tbody>
			<!-- placeholder for no results -->
			<tr class="noresults" *ngIf="us.staff?.length && !(us.staff|search: searching: 'name email inc data.address data.phone data.additionalPhone data.gender.label data.birthday.singleDate.formatted data.note')?.length">
				<td>
					<span class="icon-search"></span>
					No results found
					<div>Adjust your search criteria and try again</div>
				</td>
			</tr>

			<!-- placeholder for empty state -->
			<tr class="empty setup-empty" *ngIf="!us.staff?.length">
				<td>
					<div class="setup-empty__img"><img src="assets/img/setup/question.png" (load)="allImagesLoaded.next(true)" alt="staff" draggable="false"/></div>
					<div class="setup-empty__title">No staff yet<br><a (click)="edit({data: {}})">Click here</a> to add staff now</div>
				</td>
			</tr>

            <tr *ngFor="let user of us.staff|search: searching: 'name email inc data.address data.phone data.additionalPhone data.gender.label data.birthday.singleDate.formatted data.note'|sort:sort_type:us.now">
                <td data-label="Name" class="ovf-hidden" [class.disabled]="!(us.is.admin||us.is.owner||user._id == us._id)" [tooltip]="user.name" tooltipMobileDisabled="true" tooltipClass="tableTooltip" tooltipShowDelay="200" (click)="edit(user);">
					<div class="table__user">
						<div class="table__user__img" [class._letter]="!user.avatarUrl">
							<picture [user]="user"></picture>
						</div>
						<div class="table__user__info">
							<button class="table__user__info-name" [attr.aria-label]="'Edit ' + user.firstName + user.lastName" [disabled]="!(us.is.admin||us.is.owner||user._id == us._id)">{{user.firstName}} <wbr> {{user.lastName}}</button>
							<div class="table__user__info-desc">{{user.inc}}</div>
						</div>
					</div>
				</td>
				<td data-label="Address" class="text-elipsis" [tooltip]="user.data.address" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.address}}</td>
				<td data-label="Email" class="text-elipsis" [tooltip]="user.email" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.email}}</td>
				<td data-label="Phone" class="text-elipsis" [tooltip]="user.data.phone" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.phone}}</td>
				<td data-label="Date of Birth" class="text-elipsis" [tooltip]="user.data.birthday?.singleDate?.formatted||''" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.birthday?.singleDate?.formatted||''}}</td>
				<td data-label="Appoint">
                    <label class="waw-checkbox mb0">
                        <input type="checkbox" [(ngModel)]="user.appointment" (ngModelChange)="us.save(user)" [disabled]="!(us.is.admin||us.is.owner)&&user._id != us._id">
                        <span class="checkmark"></span>
                    </label>
                </td>
				<td data-label="Note" style="grid-column: span 3;">
					<div class="note _limit" #Note [innerHtml]="user.data.note | note" [class._more]="Note.scrollHeight > Note.clientHeight" ></div>
					<div class="note-btn">
						<button mat-icon-button type="button" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')" color="primary" aria-label="More"><span [class.icon-arrow_down]="Note.scrollHeight > Note.clientHeight" [class.icon-arrow_up]="!(Note.scrollHeight > Note.clientHeight)"></span></button>
					</div>
				</td>
            </tr>
        </tbody>
    </table>
</div>
