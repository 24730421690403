<!-- ===  Clients Service history Table === -->
<div class="table-container mobile-col3 _paginator" [class._empty]="!ap.all">
	<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
		<thead>
			<tr>
				<th scope="col" class="sort" (click)="sort('client.name')">
					<div class="ai-c">
						<span>Clients</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'client.name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'client.name'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('service.name')">
					<div class="ai-c">
						<span>Services</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'service.name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'service.name'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('day.singleDate.jsDate')">
					<div class="ai-c">
						<span>Date</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'day.singleDate.jsDate'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'day.singleDate.jsDate'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('time')">
					<div class="ai-c">
						<span>Time</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'time'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'time'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('price')">
					<div class="ai-c">
						<span>Price</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'price'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'price'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<!-- placeholder for no results -->
			<tr class="noresults" *ngIf="ap.all && !ap.past_arr?.length">
				<td>
					<span class="icon-search"></span>
					No results found
					<div>Adjust your filters and try again</div>
				</td>
			</tr>

			<!-- placeholder for empty state -->
			<tr class="empty setup-empty" *ngIf="!ap.all">
				<td>
					<div class="setup-empty__img"><img src="assets/img/setup/history.png" (load)="allImagesLoaded.next(true)" alt="history" draggable="false"/></div>
					<div class="setup-empty__title">No past appointments yet<br>As soon as the appointment time passes, it will appear on this page</div>
				</td>
			</tr>

			<tr *ngFor="let appointment of ap.past_arr">
				<td data-label="Name" class="ovf-hidden" [tooltip]="us._users[appointment.client]?.name" tooltipClass="tableTooltip" tooltipShowDelay="200">
					<div class="table__user">
						<div class="table__user__img" [class._letter]="!us._users[appointment.client]?.avatarUrl">
							<picture [user]="us._users[appointment.client]"></picture>
						</div>
						<div class="table__user__info">
							<span class="table__user__info-name">{{us._users[appointment.client]?.firstName}} <wbr> {{us._users[appointment.client]?.lastName}}</span>
							<div *ngIf="us._users[appointment.client]?.deleted" class="cl-table-clients__status">(Deleted User)</div>
						</div>
					</div>
				</td>
				<td data-label="Services" class="text-elipsis" [tooltip]="ss._services[appointment.service]?.name" tooltipClass="tableTooltip" tooltipShowDelay="200">{{ss._services[appointment.service]?.name}}</td>
				<td data-label="Date" class="text-elipsis" [tooltip]="appointment.day?.singleDate?.jsDate | date: 'EE, MMM d, y'" tooltipClass="tableTooltip" tooltipShowDelay="200">{{appointment.day?.singleDate?.formatted || ''}}</td>
				<td data-label="Time" class="text-elipsis" [tooltip]="timeFormatting(appointment.start) + ' - ' + timeFormatting(appointment.end)" tooltipClass="tableTooltip" tooltipShowDelay="200">{{timeFormatting(appointment.start)}}</td>
				<td data-label="Price" class="text-elipsis" [tooltip]="appointment.price|currency" tooltipClass="tableTooltip" tooltipShowDelay="200">{{appointment.price|currency}}</td>
			</tr>
		</tbody>
	</table>
</div>
<mat-paginator [length]="ap.count" [pageSize]="config.perPage" [hidePageSize]="true" [showFirstLastButtons]="true" (page)="handlePageEvent($event)" aria-label="Select page"></mat-paginator>
