import { Component, EventEmitter, Input, Output, Injectable, OnInit, OnChanges } from '@angular/core';
import { MatDateRangeSelectionStrategy, DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MongoService, UiService } from 'wacom';
import { modalAnimation } from 'src/app/common/animation/animation.component';
import { PlatformService, UserService } from '@services';
import { MongodatePipe } from '@pipes';

@Injectable()
export class FiveDayRangeSelectionStrategy implements MatDateRangeSelectionStrategy<string> {
    selectionFinished(date: string | null): DateRange<string> {
        return this._createFiveDayRange(date);
    }

    createPreview(activeDate: string | null): DateRange<string> {
        return this._createFiveDayRange(activeDate);
    }

    private _createFiveDayRange(date: string | null): DateRange<any> {
        if (date) {
            const d = new Date(date)
            const day = d.getDay();
            const diff = day === 0 ? -6 : 1 - day;
            const start = new Date(d.setDate(d.getDate() + diff));
            const end = new Date(d.setDate(d.getDate() + 6));
            return new DateRange<any>(start, end);
        }

        return new DateRange<string>(null, null);
    }
}

@Component({
	selector: 'datepicker-dashboard',
	templateUrl: './datepicker-dashboard.component.html',
	styleUrls: ['./datepicker-dashboard.component.scss'],
	providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: FiveDayRangeSelectionStrategy
        },
    ],
	animations: [modalAnimation]
})
export class DatepickerdashboardComponent implements OnInit, OnChanges {
    @Input() value: any;

    @Input() touchUi: boolean = false;
	
	@Input() interval: string = 'day';
	
    @Output() valueChange = new EventEmitter();

	public period: string = 'day';

	public day;

	public week: DateRange<Date>;

	public month;
	
	public maxDate: Date = new Date();

	public maxDateTime: number = this.maxDate.getTime();
	
	public minDate: Date = new Date(2022, 0, 1);

	public minDateTime: number = this.minDate.getTime();

	public thisMonthTime: number = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime();

	public year: number = new Date().getFullYear();

	public yearCalendar: number = new Date().getFullYear();

	public thisYear: number = new Date().getFullYear();

	public prevYearTime: number = new Date(this.year - 1, 11, 31).getTime();

	public nextYearTime: number = new Date(this.year + 1, 0, 1).getTime();

	public quarter: any = {
		year: new Date().getFullYear(),
		no: 1,
		months: [0, 1, 2]
	};
	
	public open: boolean = false;
	
	public period_mob: boolean = false;

	public months: any = [
		{
			label: 'JAN',
			no: 0,
			epoc: new Date(this.yearCalendar, 0, 1).getTime(),
			end: new Date(this.yearCalendar, 1, 0).getTime()
		}, {
			label: 'FEB',
			no: 1,
			epoc: new Date(this.yearCalendar, 1, 1).getTime(),
			end: new Date(this.yearCalendar, 2, 0).getTime()
		}, {
			label: 'MAR',
			no: 2,
			epoc: new Date(this.yearCalendar, 2, 1).getTime(),
			end: new Date(this.yearCalendar, 3, 0).getTime()
		}, {
			label: 'APR',
			no: 3,
			epoc: new Date(this.yearCalendar, 3, 1).getTime(),
			end: new Date(this.yearCalendar, 4, 0).getTime()
		}, {
			label: 'MAY',
			no: 4,
			epoc: new Date(this.yearCalendar, 4, 1).getTime(),
			end: new Date(this.yearCalendar, 5, 0).getTime()
		}, {
			label: 'JUN',
			no: 5,
			epoc: new Date(this.yearCalendar, 5, 1).getTime(),
			end: new Date(this.yearCalendar, 6, 0).getTime()
		}, {
			label: 'JUL',
			no: 6,
			epoc: new Date(this.yearCalendar, 6, 1).getTime(),
			end: new Date(this.yearCalendar, 7, 0).getTime()
		}, {
			label: 'AUG',
			no: 7,
			epoc: new Date(this.yearCalendar, 7, 1).getTime(),
			end: new Date(this.yearCalendar, 8, 0).getTime()
		}, {
			label: 'SEP',
			no: 8,
			epoc: new Date(this.yearCalendar, 8, 1).getTime(),
			end: new Date(this.yearCalendar, 9, 0).getTime()
		}, {
			label: 'OCT',
			no: 9,
			epoc: new Date(this.yearCalendar, 9, 1).getTime(),
			end: new Date(this.yearCalendar, 10, 0).getTime()
		}, {
			label: 'NOV',
			no: 10,
			epoc: new Date(this.yearCalendar, 10, 1).getTime(),
			end: new Date(this.yearCalendar, 11, 0).getTime()
		}, {
			label: 'DEC',
			no: 11,
			epoc: new Date(this.yearCalendar, 11, 1).getTime(),
			end: new Date(this.yearCalendar, 12, 0).getTime()
		}
	];

	public years: any = [];

	public quarterPrev: number;

	constructor(public ui: UiService, public us: UserService, private mongo: MongoService, private ps: PlatformService, private mongodatePipe: MongodatePipe) {}
	
	ngOnInit() {		
		for (var year = this.minDate.getFullYear(); year <= this.maxDate.getFullYear(); year++) {
			this.years.push(year);
		}
		this.initialize();
		
		this.mongo.on('platform', () => {
			this.minDate = new Date(this.mongodatePipe.transform(this.ps.platform._id));
			this.minDateTime = this.minDate.getTime();
		});
	}

	ngOnChanges() {
		this.initialize();
	}

	initialize() {
		this.day = this.value.start;
		this.period = this.interval;
		const d = new Date(this.day);
		const day = d.getDay();
		const diff = day === 0 ? -6 : 1 - day;
		const start = new Date(d.setDate(d.getDate() + diff));
		const end = new Date(d.setDate(d.getDate() + 6));
		this.week = new DateRange<Date>(start, end);	
		this.month = new Date(this.day.getFullYear(), this.day.getMonth(), 1);
		this.year = d.getFullYear();
		this.yearCalendar = d.getFullYear();
		this.yearChanged();
		if (d.getMonth() >= 0 && d.getMonth() <= 2) {
			this.quarter = {
				year: d.getFullYear(),
				no: 1,
				months: [0, 1, 2]
			};
		} else if (d.getMonth() >= 3 && d.getMonth() <= 5) {
			this.quarter = {
				year: d.getFullYear(),
				no: 2,
				months: [3, 4, 5]
			};
		} else if (d.getMonth() >= 6 && d.getMonth() <= 8) {
			this.quarter = {
				year: d.getFullYear(),
				no: 3,
				months: [6, 7, 8]
			};
		} else {
			this.quarter = {
				year: d.getFullYear(),
				no: 4,
				months: [9, 10, 11]
			};
		}
	}

	prev() {
		if(!this.disabledPrev) {
			switch(this.interval) {
				case 'day':
					let D = new Date(this.value.start);
					D.setDate(D.getDate() - 1);
					D.setHours(0, 0, 0);
					this.value.start = D;
					this.value.end = D;
					this.day = D;
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
				case 'week': 
					const s = new Date(this.week.start);
					s.setDate(s.getDate() - 7);
					s.setHours(0, 0, 0);
					const e = new Date(this.week.end);
					e.setDate(e.getDate() - 7);
					e.setHours(0, 0, 0);
					this.value.start = s;
					this.value.end = e;
					this.week = new DateRange<Date>(this.value.start, this.value.end);
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
				case 'month':
					const m = new Date(this.month.getFullYear(), this.month.getMonth() - 1, 1);
					m.setHours(0, 0, 0);
					this.month = m;
					this.value.start = m;
					this.value.end = new Date(m.getFullYear(), m.getMonth() + 1, 0);
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
				case 'year':
					const y = new Date(this.year - 1, 0, 1);
					y.setHours(0, 0, 0);
					this.year = y.getFullYear();
					this.value.start = y;
					this.value.end = new Date(y.getFullYear(), 11, 31);
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
				case 'quarter':
					const q_s = new Date(this.quarter.year, this.quarter.months[0] - 3, 1);
					q_s.setHours(0, 0, 0);
					const q_e = new Date(q_s.getFullYear(), q_s.getMonth() + 3, 0);
					q_e.setHours(0, 0, 0);
					this.value.start = q_s;
					this.value.end = q_e;
					this.setQuarter(q_s);
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
			}
		}
	}

	next() {
		if(!this.disabledNext) {
			switch(this.interval) {
				case 'day':
					let D = new Date(this.value.start);
					D.setDate(D.getDate() + 1);
					D.setHours(0, 0, 0);
					this.value.start = D;
					this.value.end = D;
					this.day = D;
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
				case 'week': 
					const s = new Date(this.week.start);
					s.setDate(s.getDate() + 7);
					s.setHours(0, 0, 0);
					const e = new Date(this.week.end);
					e.setDate(e.getDate() + 7);
					e.setHours(0, 0, 0);
					this.value.start = s;
					this.value.end = e;
					this.week = new DateRange<Date>(this.value.start, this.value.end);
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
				case 'month':
					const m = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 1);
					m.setHours(0, 0, 0);
					this.month = m;
					this.value.start = m;
					this.value.end = new Date(m.getFullYear(), m.getMonth() + 1, 0);
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
				case 'year':
					const y = new Date(this.year + 1, 0, 1);
					y.setHours(0, 0, 0);
					this.year = y.getFullYear();
					this.value.start = y;
					this.value.end = new Date(y.getFullYear(), 11, 31);
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
				case 'quarter':
					const q_s = new Date(this.quarter.year, this.quarter.months[0] + 3, 1);
					q_s.setHours(0, 0, 0);
					const q_e = new Date(q_s.getFullYear(), q_s.getMonth() + 3, 0);
					q_e.setHours(0, 0, 0);
					this.value.start = q_s;
					this.value.end = q_e;
					this.setQuarter(q_s);
					this.valueChange.emit({interval: this.interval, selector: this.value});
					break;
			}
		}
	}

	selectedChange(event) {
		switch(this.period) {
			case 'week':
				const d = new Date(event);
				const day = d.getDay();
				const diff = day === 0 ? -6 : 1 - day;
				const start = new Date(d.setDate(d.getDate() + diff));
				const end = new Date(d.setDate(d.getDate() + 6));
				this.week = new DateRange<Date>(start, end);	
				break;
			case 'month':
				this.month = new Date(event);
				break;
			case 'year':
				this.year = event;
				break
			case 'quarter':
				this.setQuarter(new Date(event));
				break;
		}
	}

	changePeriod(period) {
		this.yearCalendar = new Date(this.day).getFullYear();
		this.yearChanged();
		this.period = period; 
		this.period_mob = false;
	}

	clear() {
		this.open = false;
		this.value.start = new Date();
		this.value.end = new Date();
		this.day = new Date();
		this.interval = 'day';
		this.period = 'day';
		this.valueChange.emit({interval: this.interval, selector: this.value});
	}

	save() {
		switch(this.period) {
			case 'day':
				this.value.start = this.day;
				this.value.end = this.day;
				break;
			case 'week':
				this.value.start = this.week.start;
				this.value.end = this.week.end;
				break;
			case 'month':
				this.value.start = this.month;
				this.value.end = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 0);
				break;
			case 'year':
				this.value.start = new Date(this.year, 0, 1);
				this.value.end = new Date(this.year, 11, 31);
				break;
			case 'quarter':
				this.value.start = new Date(this.quarter.year, this.quarter.months[0], 1);
				this.value.end = new Date(this.quarter.year, this.quarter.months[2] + 1, 0);
				break;
		}
		this.interval = this.period;
		this.valueChange.emit({interval: this.interval, selector: this.value});
		this.open = false;
	}

	get activePeriod() {
		switch(this.period) {
			case 'day':
				return (36 + 10) * 0 + 'px';
			case 'week':
				return (36 + 10) * 1 + 'px';
			case 'month':
				return (36 + 10) * 2 + 'px';
			case 'quarter':
				return (36 + 10) * 3 + 'px';
			case 'year':
				return (36 + 10) * 4 + 'px';
		}
	}

	prevYear() {
		if(this.prevYearTime < this.minDateTime) return;
		else {
			this.yearCalendar = new Date(this.yearCalendar - 1, 0, 1).getFullYear();
			this.yearChanged();
		}
	}

	nextYear() {
		if(this.nextYearTime > this.maxDateTime) return;
		else {
			this.yearCalendar = new Date(this.yearCalendar + 1, 0, 1).getFullYear();
			this.yearChanged();
		}
	}

	yearChanged() {
		this.prevYearTime = new Date(this.yearCalendar - 1, 11, 31).getTime();
		this.nextYearTime = new Date(this.yearCalendar + 1, 0, 1).getTime();
		for(let month of this.months) {
			month.epoc = new Date(this.yearCalendar, month.no, 1).getTime();
			month.end = new Date(this.yearCalendar, month.no + 1, 0).getTime();
		}
	}

	getQuarter() {
		const d = new Date(this.value.start);
		if (d.getMonth() >= 0 && d.getMonth() <= 2) {
			return 'Quarter 1, ' + d.getFullYear();
		} else if (d.getMonth() >= 3 && d.getMonth() <= 5) {
			return 'Quarter 2, ' + d.getFullYear();
		} else if (d.getMonth() >= 6 && d.getMonth() <= 8) {
			return 'Quarter 3, ' + d.getFullYear();
		} else {
			return 'Quarter 4, ' + d.getFullYear();
		}
	}

	setQuarter(date: Date) {
		if (date.getMonth() >= 0 && date.getMonth() <= 2) {
			this.quarter = {
				year: date.getFullYear(),
				no: 1,
				months: [0, 1, 2]
			};
		} else if (date.getMonth() >= 3 && date.getMonth() <= 5) {
			this.quarter = {
				year: date.getFullYear(),
				no: 2,
				months: [3, 4, 5]
			};
		} else if (date.getMonth() >= 6 && date.getMonth() <= 8) {
			this.quarter = {
				year: date.getFullYear(),
				no: 3,
				months: [6, 7, 8]
			};
		} else {
			this.quarter = {
				year: date.getFullYear(),
				no: 4,
				months: [9, 10, 11]
			};
		}
	}

	get disabledNext() {
		const maxDate = new Date(this.maxDate);
		const minDate = new Date(this.minDate);
		maxDate.setHours(0, 0, 0);
		minDate.setHours(0, 0, 0);

		switch(this.interval) {
			case 'day':
				let D = new Date(this.value.start);
				D.setDate(D.getDate() + 1);
				D.setHours(0, 0, 0);
				if( D <= maxDate && D >= minDate ) return false;
				else return true;
			case 'week': 
				const s = new Date(this.week.start);
				s.setDate(s.getDate() + 7);
				s.setHours(0, 0, 0);
				const e = new Date(this.week.end);
				e.setDate(e.getDate() + 7);
				e.setHours(0, 0, 0);
				if( s <= maxDate && e >= minDate ) return false;
				else return true;
			case 'month':
				const m = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 1);
				m.setHours(0, 0, 0);
				if( m <= maxDate && m >= minDate ) return false;
				else return true;
			case 'year':
				const y = new Date(this.year + 1, 0, 1);
				y.setHours(0, 0, 0);
				if( y <= maxDate && y >= minDate ) return false;
				else return true;
			case 'quarter':
				const q_s = new Date(this.quarter.year, this.quarter.months[0] + 3, 1);
				q_s.setHours(0, 0, 0);
				const q_e = new Date(q_s.getFullYear(), q_s.getMonth() + 3, 0);
				q_e.setHours(0, 0, 0);
				if( q_s <= maxDate && q_e >= minDate ) return false;
				else return true;
		}
	}

	get disabledPrev() {
		const maxDate = new Date(this.maxDate);
		const minDate = new Date(this.minDate);
		maxDate.setHours(0, 0, 0);
		minDate.setHours(0, 0, 0);

		switch(this.interval) {
			case 'day':
				let D = new Date(this.value.start);
				D.setDate(D.getDate() - 1);
				D.setHours(0, 0, 0);
				if( D <= maxDate && D >= minDate ) return false;
				else return true;
			case 'week': 
				const s = new Date(this.week.start);
				s.setDate(s.getDate() - 7);
				s.setHours(0, 0, 0);
				const e = new Date(this.week.end);
				e.setDate(e.getDate() - 7);
				e.setHours(0, 0, 0);
				if( s <= maxDate && e >= minDate ) return false;
				else return true;
			case 'month':
				const m = new Date(this.month.getFullYear(), this.month.getMonth() - 1, 1);
				m.setHours(0, 0, 0);
				if( m <= maxDate && m >= minDate ) return false;
				else return true;
			case 'year':
				const y = new Date(this.year - 1, 0, 1);
				y.setHours(0, 0, 0);
				if( y.getFullYear() <= maxDate.getFullYear() && y.getFullYear() >= minDate.getFullYear() ) return false;
				else return true;
			case 'quarter':
				const q_s = new Date(this.quarter.year, this.quarter.months[0] - 3, 1);
				q_s.setHours(0, 0, 0);
				const q_e = new Date(q_s.getFullYear(), q_s.getMonth() + 3, 0);
				q_e.setHours(0, 0, 0);
				if( q_s <= maxDate && q_e >= minDate ) return false;
				else return true;
		}
	}
}