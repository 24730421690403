<div class="containerBox">
	<div class="setup-empty setup-box" *ngIf="!ts.taxes.length">
		<div class="setup-empty__img"><img src="assets/img/setup/taxes.png" alt="discount" draggable="false"/></div>
		<div class="setup-empty__title">Please add your state or province tax here</div>
		<div class="setup-empty__btn" *ngIf="us.is.admin||us.is.owner">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openTax()">
				<span class="icon-plus"></span>
				<span>Add tax</span>
			</button>
		</div>
	</div>
	<div class="setup-container" *ngIf="ts.taxes.length">
		<div class="setup-item setup-box" *ngFor="let tax of ts.taxes">
			<div class="setup-item__title">{{tax.name}}</div>
			<div class="setup-item__actions" *ngIf="us.is.admin||us.is.owner">
				<button mat-icon-button color="primary" class="action-edit" (click)="openTax(tax);"><span class="icon-edit"></span></button>
				<button mat-icon-button color="warn" class="action-delete" (click)="delete(tax);"><span class="icon-delete"></span></button>
			</div>
			<hr>
			<div class="setup-item__property">
				<div class="setup-item__property__name">{{tax.amount}} %</div>
			</div>
		</div>
		<div class="setup-add" *ngIf="us.is.admin||us.is.owner">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openTax()">
				<span class="icon-plus"></span>
				<span>Add tax</span>
			</button>
		</div>
	</div>
</div>