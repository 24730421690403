import { Component, ViewChildren, ElementRef, QueryList, OnInit, OnChanges, Input, AfterViewInit, OnDestroy, SimpleChanges } from '@angular/core';
import { UserService, TimeService, LocationService, ClosedService, LoaderService } from '@services';
import { MongoService } from 'wacom';
import { ModalService, UiService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { WorkingusersPipe } from '@pipes';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-working-hours',
	templateUrl: './working-hours.component.html',
	styleUrls: ['./working-hours.component.scss'],
	animations: [
		trigger('height', [
			state('in', style({ height: '*' })),
			transition('void => *', [
				style({
					height: 0,
					opacity: 0,
				}),
				animate(200)
			]),
			transition('* => void', [
				animate(100, style({
					opacity: 0,
					height: 0,
				}))
			])
		])
	]
})
export class WorkingHoursComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
	@Input() searching: string = '';
	@Input() view: boolean = true;
	@Input() weekStart: number;
	@Input() month: number;
	@Input() year: number;
	public show_more: any = null;
	public show_all: any = null;
	public show_date: any = [];
	public number_of_employees_show: number = 4;
	public positions = [
		new ConnectionPositionPair(
			{
				originX: 'center',
				originY: 'top'
			}, {
				overlayX: 'center',
				overlayY: 'top'
			}, 0, 0)
	];
	public sort_type:any = {};
	public staff: any = [];
	public x = [0,1,2,3,4,5,6]
	private isClosedDate: any = {};
	public dateWorkingHours: any = {};
	public monthWorkingHours: any = {};
	public dateWorkingUsers: any = {};
	private afterViewInit: boolean = false;
	private refreshInterval: any = null;
	private refreshNow;
	public allImagesLoaded = new BehaviorSubject <boolean>(false);
	constructor(public us: UserService, private mongo: MongoService,
		public ts: TimeService, public modal: ModalService,  public ls: LocationService, public cs: ClosedService,
		public router: Router,private route: ActivatedRoute,
		public ui: UiService,
		private datePipe: DatePipe,
		private workingUsersPipe: WorkingusersPipe,
		private loader: LoaderService,
		private eref: ElementRef) { }
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
		
		this.mongo.on('user', ()=> {
			this.refreshNow = this.us.now;
			this.refreshInterval = setInterval(() => {
				if (this.refreshNow != this.us.now) {
					this.refreshNow = this.us.now;
					if(this.view) {
						if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));
						this.Week(true);
					} else {
						if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));
						this.Month(true);
					}
				}
			}, 100);
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes['weekStart']) {
			this.ts.get(new Date(changes['weekStart'].currentValue).toDateString(), new Date(changes['weekStart'].currentValue + (6*86400000)).toDateString());
		}
		
		if(this.view) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
			this.Week();
		} else {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
			this.Month();
		}		
		this.ts.refresh();
	}
	ngAfterViewInit() {
		this.afterViewInit = true;
		if (this.us.users?.length && this.view) {
			this.initializeWeekWorkingHours();
		} else if (this.us.users?.length && !this.view) {
			this.initializeMonthWorkingHours();
		}
	}
	ngOnDestroy() {
		clearInterval(this.refreshInterval);
	}
	set_user_id(user_id) {
		this.mongo.on('user', () => {
			if (this.us._users[user_id]) this.staff = [this.us._users[user_id]];
		});
	}
	timeFormatting(time) {
		var d = new Date();
		if(time) {
			d.setHours(time.split(':')[0]);
			d.setMinutes(time.split(':')[1]);
			if(d.getMinutes() != 0) {
				return this.datePipe.transform(d, 'h:mm a');
			} else {
				return this.datePipe.transform(d, 'h a');
			}
		} else {
			return '';
		}
	}
	timeRange(user, day, date) {
		if (user.data.working_hours[date]?.hours?.length) {
			const res = [];
			for(const [index, hour] of user.data.working_hours[date].hours.entries()) {
				res.push(this.timeFormatting(hour.from) + ' - ' + this.timeFormatting(hour.to) + ((index != user.data.working_hours[date].hours.length - 1) ? ' / ' : ''));
			}
			return res;
		} else if(user.data.working_hours.default[day]?.length){
			const res = [];
			for(const [index, hour] of user.data.working_hours.default[day].entries()) {
				res.push(this.timeFormatting(hour.from) + ' - ' + this.timeFormatting(hour.to) + ((index != user.data.working_hours.default[day].length - 1) ? ' / ' : ''));
			}
			return res;
		}
	}
	editWorkingHours(user, day = null) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		if(day) {
			this.modal.show({component: 'workingHours', selected_user: JSON.parse(JSON.stringify(user)), date: day, select_day: true, edit: true, add: false});
		} else {
			this.modal.show({component: 'workingHours', selected_user: JSON.parse(JSON.stringify(user)), new_time: {from: user.data.working_hours?.default?.from || '', to: user.data.working_hours?.default?.to || ''}});
		}
	}
	addWorkingHours(day, user = {_id: ''}) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		if (user._id) {
			this.modal.show({component: 'workingHours', selected_user: JSON.parse(JSON.stringify(user)), date: day, select_day: true, edit: false});
		} else  {
			this.modal.show({component: 'workingHours', date: day, select_day: true, edit: false});
		}
	}
	editWorkingDays(user) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'workingDays', selected_user: JSON.parse(JSON.stringify(user))});
	}
	isClosed(staff, date) {
		const d = new Date(date);
		d.setHours(0, 0, 0, 0);
		
		if(this.isClosedDate[this.datePipe.transform(d, 'shortDate') + staff._id] != undefined) return this.isClosedDate[this.datePipe.transform(d, 'shortDate') + staff._id];
		
		for (let i = this.cs.closeds.length-1; i >= 0; i--){
			for (let location of staff.location) {
				if(
					this.cs.closeds[i].locations.find(l => l == location) && 
					(
						(
							!this.cs.closeds[i].holiday &&
							new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
							new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(d)
						) ||
						(
							this.cs.closeds[i].holiday &&
							this.cs.isHoliday(new Date(d), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
						)
					)
				) {	
					this.isClosedDate[this.datePipe.transform(d, 'shortDate') + staff._id] = this.cs.closeds[i];
					return this.cs.closeds[i];
				}
			}
		}

		this.isClosedDate[this.datePipe.transform(d, 'shortDate') + staff._id] = false;
		return false;
	}
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	public weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	public months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	public dates = [];
	public weeks = [];
	  
	Week(refresh = false){
		if(!this.us.users?.length) {
			this.mongo.on('user location closed', () => {
				this.initializeWeekWorkingHours(refresh);
			});
		} else if(this.afterViewInit) {
			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					this.initializeWeekWorkingHours(refresh);
					clearInterval(waitForLoader);
				}
			}, 1);
		}
	}
	removeLoader() {
		if(!this.us.staff?.length) {
			this.allImagesLoaded.subscribe((state: boolean) => {
				if(state === true) {
					this.loader.remove();
				}
			});
		} else {
			this.loader.remove();
		}
	}
	initializeWeekWorkingHours(refresh = false) {
		for (let user of this.us.staff) {
			for (let i = 0; i < this.x.length; i++) {
				const time = this.weekStart+(i*86400000);
				
				if(!this.dateWorkingHours[user._id+time] || refresh) {
					this.initializeDateWorkingHours(user, time);
				}
			}
		}
		this.ts.loaded(() => {
			this.removeLoader();
		});
	}
	Month(refresh = false) {
		this.dates = [];
		this.dates = this.getDates();
		this.weeks = this.ui.arr(this.dates.length / 7);
		this.ts.refresh();

		if(!this.us.users?.length) {
			this.mongo.on('user location closed', () => {
				this.initializeMonthWorkingHours(refresh);
			});
		} else if(this.afterViewInit) {
			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					this.initializeMonthWorkingHours(refresh);
					clearInterval(waitForLoader);
				}
			}, 1);
		}
	}
	initializeMonthWorkingHours(refresh = false) {
		for (let i = 0; i < this.dates.length; i++) {
			if (this.dates[i]?.month == '_this') {
				const time = this.dates[i].time;

				if (!this.dateWorkingUsers[time] || refresh) this.dateWorkingUsers[time] = this.workingUsersPipe.transform(this.us.staff, time);

				for (let user of this.us.staff) {
					if(!this.dateWorkingHours[user._id+time] || refresh) {
						this.initializeDateWorkingHours(user, time);
					}
				}
			}
		}
		setTimeout(() => {
			this.removeLoader();
		}, 200);
	}
	initializeDateWorkingHours(user, time) {
		const closed = this.isClosed(user, time);
		const day = this.datePipe.transform(time,'EEEE');
		const date = this.datePipe.transform(time,'M/d/yyyy');

		if(( !user.data?.working_hours?.default?.[day]?.length && !user.data?.working_hours?.[date]?.hours?.length )||closed) {
			// closed or time off
			this.dateWorkingHours[user._id+time] = {
				type: 'off',
				text: closed.name||'Off'
			};
			return;
		} else if(user.data?.working_hours?.[date]?.vacation) {
			// add working hours
			this.dateWorkingHours[user._id+time] = {
				type: 'on',
				text: 'On'
			};
			return;
		} else if(user.data?.working_hours?.[date]?.hours?.length) {
			// working hours for a specific date
			this.dateWorkingHours[user._id+time] = {
				type: 'working',
				text: this.timeFormatting(user.data.working_hours[date].hours[0].from)	 + ' - ' + (user.data.working_hours[date].hours.length > 1 ? '... - ' : '') + this.timeFormatting(user.data.working_hours[date].hours[user.data.working_hours[date].hours.length - 1].to),
				hours: this.timeRange(user, day, date)
			};
			return;
		} else if(user.data?.working_hours?.default?.[day]?.length) {
			// working hours
			this.dateWorkingHours[user._id+time] = {
				type: 'working',
				text: this.timeFormatting(user.data.working_hours.default[day][0].from) + ' - ' + (user.data.working_hours.default[day].length > 1 ? '... - ' : '') + this.timeFormatting(user.data.working_hours.default[day][user.data.working_hours.default[day].length - 1].to),
				hours: this.timeRange(user, day, date)
			};
			return;
		}
	}
	getDates() {
		let buf = [];
		let D = new Date();
		let Dfirst = new Date(this.year, this.month, 1, 12);
		let Dlast = new Date(this.year, this.month+1, 0, 12);
		let DWfirst = Dfirst.getDay();
		let DWlast = Dlast.getDay();
		let start = new Date(this.year, this.month,1 - DWfirst, 12);
		let end = new Date(this.year, this.month, 0, 12);
		if (new Date(this.year,this.month,1 - DWfirst).getMonth() != Dfirst.getMonth()) {
			for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
				buf.push({
					date: d.getDate(), 
					time: d.getTime(),
					month: '_last'
				});
			}
		}
		for(let d = new Date(Dfirst); d <= Dlast; d.setDate(d.getDate() + 1)) {
			buf.push({
				date: d.getDate(), 
				time: d.getTime(),
				month: '_this'
			});
		}
		start = new Date(Dlast.setDate(Dlast.getDate() + 1));
		end = new Date(Dlast.setDate(Dlast.getDate() + (6 - DWlast)));
		for (let d = new Date(start); d < end; d.setDate(d.getDate() + 1)) {
			buf.push({
				date: d.getDate(), 
				time: d.getTime(),
				month: '_last'
			});
		}
		return buf;
	}
	scrollTo(date) {
		document.querySelector('[data-date="' + date + '"]').scrollIntoView({ behavior: 'smooth' });
	}
	@ViewChildren('monthDay') monthDays: QueryList<ElementRef>;
	public monthDate: number = null;
	checkElementsInView(){
		if(this.monthDays) {
			const containerBox = document.querySelector('.containerBox') as HTMLElement;
			this.monthDays.some((d) => {
				const rect = d.nativeElement.getBoundingClientRect();
				const isVisible = rect.height > 0 && rect.bottom > (containerBox.getBoundingClientRect().top + 40);
				if (isVisible) {
					this.monthDate = Number(d.nativeElement.dataset.date);
					return true;
				}
				return false;
			});
		}
	}
	getTooltipWorkingHours(date, text, name = '') {
		if(!name) {
			return `${this.datePipe.transform(date, 'EE, MMM d, y')}
			${text}`;
		} else if(text) {
			return `${this.datePipe.transform(date, 'EE, MMM d, y')}
			${name}
			${text}`;
		} else {
			return `${this.datePipe.transform(date, 'EE, MMM d, y')}
			${name}`;
		}
	}
	positionCheck() {
		const panelClass = `.panel${this.show_more ? this.show_more : this.show_all}`;
		const panel = document.querySelector(panelClass) as HTMLElement;
		const containerBox = document.querySelector('.containerBox') as HTMLElement;
		const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		var translateY = -60;
		var translateX = 0;
		var maxHeight = (60 / 100) * vh;
		var width = 280;

		// top
		if(panel.getBoundingClientRect().top + translateY < containerBox.getBoundingClientRect().top + 10) {
			translateY += (containerBox.getBoundingClientRect().top + 10) - (panel.getBoundingClientRect().top + translateY);
		}
		// bottom
		const allHeight = panel.querySelector('.all-items__list').scrollHeight + (panel.querySelector('.all-items').clientHeight - panel.querySelector('.all-items__list').clientHeight);
		if(panel.getBoundingClientRect().top + allHeight + translateY + 10 > vh) {
			if(allHeight < maxHeight) {
				translateY += vh - (panel.getBoundingClientRect().top + allHeight + translateY + 10);
			} else if(panel.getBoundingClientRect().top + maxHeight + translateY + 10 > vh) {
				translateY += vh - (panel.getBoundingClientRect().top + maxHeight + translateY + 10);
			}
		}
		// left
		if(panel.getBoundingClientRect().left + translateX < containerBox.getBoundingClientRect().left + 40) {
			translateX += (containerBox.getBoundingClientRect().left + 40) - (panel.getBoundingClientRect().left + translateX);
		}
		// right
		if(panel.getBoundingClientRect().left + width + translateX + 10 > vw) {
			translateX += vw - (panel.getBoundingClientRect().left + width + translateX + 10);
		}

		panel.style.transform = `translate(${translateX}px, ${translateY}px)`;
		panel.style.maxHeight = `calc(100% + ${Math.abs(translateY)}px)`;
		panel.style.maxWidth = `calc(100% + ${Math.abs(translateX)}px)`;
	}
}
