<!-- ===  Clients List Table === -->
<div class="table-container tablet-col3 mobile-col3" [class._empty]="!us.clients?.length">
	<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
		<thead>
			<tr>
				<th scope="col" class="sort" (click)="sort('name')">
					<div class="ai-c">
						<span>Client name</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'name'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('data.address')">
					<div class="ai-c">
						<span>Address</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.address'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.address'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('email')">
					<div class="ai-c">
						<span>Email</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'email'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'email'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('data.phone')">
					<div class="ai-c">
						<span>Phone</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.phone'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.phone'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('data.gender')">
					<div class="ai-c">
						<span>Gender</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.gender'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.gender'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('data.birthday.singleDate.epoc')">
					<div class="ai-c">
						<span>Date of Birth</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.birthday.singleDate.epoc'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.birthday.singleDate.epoc'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('_id')">
					<div class="ai-c">
						<span>Client Since</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == '_id'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == '_id'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<!-- placeholder for no results -->
			<tr class="noresults" *ngIf="us.clients?.length && !(us.clients|search: searching: 'name email inc data.address data.phone data.additionalPhone data.gender data.birthday.singleDate.formatted')?.length">
				<td>
					<span class="icon-search"></span>
					No results found
					<div>Adjust your search criteria and try again</div>
				</td>
			</tr>

			<!-- placeholder for empty state -->
			<tr class="empty setup-empty" *ngIf="!us.clients?.length">
				<td>
					<div class="setup-empty__img"><img src="assets/img/setup/question.png" (load)="allImagesLoaded.next(true)" alt="clients" draggable="false"/></div>
					<div class="setup-empty__title">No clients yet<br><a (click)="edit({data: {}})">Click here</a> to add a client now</div>
				</td>
			</tr>

			<tr *ngFor="let user of us.clients|search: searching: 'name email inc data.address data.phone data.additionalPhone data.gender data.birthday.singleDate.formatted'|sort: sort_type:us.now">
				<td data-label="Name" class="ovf-hidden"(click)="menuTrigger.openMenu()" [tooltip]="user.name" tooltipMobileDisabled="true" tooltipClass="tableTooltip" tooltipShowDelay="200">
					<div class="table__user" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="actions">
						<div class="table__user__img" [class._letter]="!user.avatarUrl">
							<picture [user]="user"></picture>
						</div>
						<div class="table__user__info">
							<button class="table__user__info-name" [attr.aria-label]="'Profile ' + user.firstName + user.lastName">{{user.firstName}} <wbr> {{user.lastName}}</button>
							<div class="table__user__info-desc">{{user.inc}}</div>
						</div>
					</div>
					<mat-menu #actions="matMenu" xPosition="after" yPosition="below" backdropClass="menuBackdrop">
						<div class="matMenu__header" (click)="$event.stopPropagation()">
							<div class="matMenu__header-img" [class._letter]="!user.avatarUrl">
								<picture [user]="user" [big]="true"></picture>
							</div>
							<div class="matMenu__header-name">{{user.name}}</div>
						</div>
						<button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" aria-label="View profile" (click)="profile(user)">
							<span class="matMenu__item-icon icon-profile"></span>
							<span>View profile</span>
						</button>
						<button class="matMenu__item" [disabled]="!(us.is.admin||us.is.owner)" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" aria-label="Edit profile" (click)="edit(user)">
							<span class="matMenu__item-icon icon-edit"></span>
							<span>Edit profile</span>
						</button>
					</mat-menu>
				</td>
				<td data-label="Address" class="text-elipsis" [tooltip]="user.data.address" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.address}}</td>
				<td data-label="Email" class="text-elipsis" [tooltip]="user.email" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.email}}</td>
				<td data-label="Phone" class="text-elipsis" [tooltip]="user.data.phone" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.phone}}</td>
				<td data-label="Gender" class="text-elipsis" [tooltip]="user.data.gender" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.gender}}</td>
				<td data-label="Date of Birth" class="text-elipsis" [tooltip]="user.data.birthday?.singleDate?.formatted||''" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.birthday?.singleDate?.formatted||''}}</td>
				<td data-label="Client Since" class="text-elipsis" style="grid-column: span 2;" [tooltip]="user._id|mongodate|date:'M/d/yyyy'" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user._id|mongodate|date:'M/d/yyyy'}}</td>
			</tr>
		</tbody>
	</table>
</div>
