<div class="setup-empty setup-box" *ngIf="!bs.brands?.length">
    <div class="setup-empty__img"><img src="assets/img/setup/brands.png" (load)="allImagesLoaded.next(true)" alt="brands" draggable="false"/></div>
    <div class="setup-empty__title">No brands yet<br><a (click)="openModal()">Click here</a> to add a brand now</div>
</div>

<div class="setup-container" *ngIf="bs.brands?.length">
    <div class="setup-item setup-box" *ngFor="let brand of bs.brands">
        <div class="setup-item__title">{{brand.name}}</div>
        <div class="setup-item__actions" *ngIf="us.is.admin||us.is.owner">
            <button mat-icon-button color="primary" class="action-edit" (click)="openModal(brand);"><span class="icon-edit"></span></button>
            <button mat-icon-button color="warn" class="action-delete" (click)="delete(brand);"><span class="icon-delete"></span></button>
        </div>
    </div>
</div>