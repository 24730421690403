import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';
import { UserService } from './user.service';
import { DatePipe } from '@angular/common';
import { BehaviorSubject  } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
	providedIn: 'root'
})
export class CheckoutService {
	public checks: any = [];
	public _checks: any = {
		date: {},
		day: {},
		month: {},
	};
	public all: number = 0;
	public count: number = 0;
	private config: any = {
		interval: 'day',
		selector: {
			start: null,
			end: null
		},
		location: null
	};
	public now;
	private loadedSubject = new BehaviorSubject <boolean>(false);
	public types: any = ['Cash', 'Credit Card', 'Debit Card'];
	constructor(private mongo: MongoService, private alert: AlertService, private us: UserService, private http: HttpClient, private datePipe: DatePipe) { 
		this.mongo.config('checkout', {
			replace: {
				staffName: (val, cb, doc)=>{					
					cb(this.us._users[doc.staff?._id || doc.staff]?.name);
				},
				clientName: (val, cb, doc)=>{					
					cb(this.us._users[doc.client?._id || doc.client]?.name);
				},
				timestamp: (val, cb, doc)=>{					
					if(doc.creation_date?.singleDate?.jsDate) {
						cb(new Date(doc.creation_date?.singleDate?.jsDate).getTime());
					}
				},
			},
			populate: [{
				field: 'staff',
				part: 'user'
			}],
			groups: {
				date: {
					field: (doc, cb) => {
						if(doc.creation_date){
							cb(doc.creation_date.singleDate.formatted);
						}
					}
				},
				hours: {
					field: (doc, cb) => {
						if(doc.creation_date){
							cb(doc.creation_date.singleDate.date.hours);
						}
					}
				},
				day: {
					field: (doc, cb) => {
						if(doc.creation_date){
							cb(doc.creation_date.singleDate.date.day);
						}
					}
				},
				month: {
					field: (doc, cb) => {
						if(doc.creation_date){
							cb(doc.creation_date.singleDate.date.month);
						}
					}
				}
			},
		});
	}
	create(checkout:any={}, cb:any=event=>{}) {
		if(checkout._id) return;
		// if(checkout._id) return this.save(checkout);

		const date = new Date();
		checkout.creation_date = {
			singleDate: { 
				date: {
					year: date.getFullYear(),
					month: date.getMonth() + 1,
					day: date.getDate(),
					hours: date.getHours()
				},
				jsDate: date,
				formatted: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
				epoc: date.getTime()
			}
		}

		this.mongo.create('checkout', checkout, created=>{
			if(typeof cb === 'function') cb(created);
			this.now = Date.now();
			this.alert.show({
				text: 'Checkout has been added.'
			});
		}); 
	}
	// update(checkout) {
	// 	this.mongo.afterWhile(checkout, ()=> {
	// 		this.save(checkout);
	// 	});
	// }
	// save(checkout){
	// 	this.mongo.update('checkout', checkout, ()=>{
	// 		this.now = Date.now()
	// 		this.alert.show({
	// 			text: 'Checkout has been updated.'
	// 		});
	// 	});
	// }
	// delete(checkout) {
	// 	this.mongo.delete('checkout', checkout, ()=>{
	// 		this.now = Date.now()
	// 		this.alert.show({
	// 			text: 'Checkout has been deleted.'
	// 		});
	// 	});
	// }
	isItPaid(appointment, cb:any=event=>{}) {
		this.http.post('/api/checkout/paid', {appointment: appointment}).subscribe({
			next: (resp: any) => {
				if(typeof cb === 'function') cb(resp);
			}
		});	
	}
	getChecks(interval = 'day', selector = null, location = null): Promise<any> {		
		this.loadedSubject.next(false);
		if( this.config.interval === interval && new Date(this.config.selector.start).toDateString() == new Date(selector.start).toDateString() && new Date(this.config.selector.end).toDateString() == new Date(selector.end).toDateString() && this.config.location === location ) {
			return new Promise((resolve, reject) => {
				this.loadedSubject.next(true);
				resolve(this.checks);
			});
		} else {
			this.config.interval = interval;
			this.config.location = location;
			this.config.selector.start = selector.start;
			this.config.selector.end = selector.end;

			const req: any = {
				interval: interval,
				location: location
			};
			
			if(selector) {
				switch(interval) {
					case 'day':
						req.date = `${selector?.start.getMonth() + 1}/${selector?.start.getDate()}/${selector?.start.getFullYear()}`;
						break;
					case 'week':
						req.dates = [];
						for (let d = new Date(selector?.start); d <= new Date(selector?.end); d.setDate(d.getDate() + 1)) {
							req.dates.push(`${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`);
						}
						break;
					case 'month':
						req.month = selector?.start.getMonth() + 1;
						req.year = selector?.start.getFullYear();
						break;
					case 'year':
						req.year = selector?.start.getFullYear();
						break;
					case 'quarter':
						req.months = [];
						for (let d = new Date(selector?.start); d <= new Date(selector?.end); d.setMonth(d.getMonth() + 1)) {
							req.months.push(d.getMonth() + 1);
						}
						req.year = selector?.start.getFullYear();
						break;
				}
			}
			
			return new Promise((resolve, reject) => {
				this.http.post('/api/checkout/daterange', req).subscribe({
					next: (resp: any) => {
						this.checks = resp.arr;
						this._checks = resp.obj;
						
						this.now = Date.now();
						this.loadedSubject.next(true);
						resolve(resp);
					},
					error: (error) =>{
						console.error(error);
						reject(error);
					}
				});
			});
		}
	}

	loaded(cb:any=event=>{}) {
		if(this.loadedSubject.getValue() === true) {			
			if(typeof cb === 'function') cb(true);
		} else {
			this.loadedSubject.subscribe((state: boolean) => {
				if(state === true) {
					if(typeof cb === 'function') cb(true);
				}
			});
		}
	}

	clear() {
		const checks = [...this.checks];
		for (let i = 0; i < checks.length; i++) {
			this.mongo.remove('checkout', checks[i]);
		}
		this._checks.date = null;
		this._checks.day = null;
		this._checks.month = null;
		this.config.interval = 'day';
		this.config.location = null;
		this.config.selector.start = null;
		this.config.selector.end = null;
	}

	getChecksPagination(config, search: string = '', date = null, type, sort_type, cb:any=event=>{}) {
		this.loadedSubject.next(false);
		const skip = (config.page - 1) * config.perPage;
		const params = { 
			skip: skip, 
			limit: config.perPage,
			search: search,
			date: date ? date : '',
			type: type ? type : '',
			sortField: sort_type.title ? sort_type.title : '',
			sortOrder: sort_type.direction ? sort_type.direction : ''
		};

		this.http.post('/api/checkout/pagination', params).subscribe((resp: any) => {
			if(resp != false) {
				if(resp.all == 0) {
					this.all = 0;
					this.checks = [];

					this.loadedSubject.next(true);
					if(typeof cb === 'function') cb(true);

					return;
				} else {
					this.all = resp.all;
					this.count = resp.count;
					this.mongo.on('user', () => {
						const checks = [...this.checks];
						for (let i = 0; i < checks.length; i++) {
							this.mongo.remove('checkout', checks[i]);
						}
						const { arr, obj } = this.mongo.set('checkout', resp.arr);
						
						this.checks = arr;
						
						this._checks = obj;
						
						this.now = Date.now();
						this.loadedSubject.next(true);
						if(typeof cb === 'function') cb(true);
					});
				}
			}
		});
	}
}
