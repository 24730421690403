<!-- ===  Staff List Table === -->
<ng-container *ngIf="view == true">
	<div class="table-container week tablet-col3 mobile-col3" [class._empty]="!us.staff?.length">
		<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
			<thead>
				<tr>
					<th scope="col" class="sort" (click)="sort('name')">
						<div>
							<div class="ai-c">
								<span>Staff</span> 
								<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'name'">
									<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
									<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
								</span>
							</div>
						</div>
					</th>
					<th scope="col" *ngFor="let add of x">
						<div class="table__header-date">
							<span>{{(weekStart+(add*86400000))|date}}</span>
							<span>{{(weekStart+(add*86400000))|date: 'EE'}}</span>
						</div>
					</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<!-- placeholder for no results -->
				<tr class="noresults" *ngIf="us.staff?.length && !(us.staff|search: searching: 'name')?.length">
					<td>
						<span class="icon-search"></span>
						No results found
						<div>Adjust your search criteria and try again</div>
					</td>
				</tr>
				
				<!-- placeholder for empty state -->
				<tr class="empty setup-empty" *ngIf="!us.staff?.length">
					<td>
						<div class="setup-empty__img"><img src="assets/img/setup/question.png" (load)="allImagesLoaded.next(true)" alt="staff" draggable="false"/></div>
						<div class="setup-empty__title">No staff yet</div>
					</td>
				</tr>
				
				<tr *ngFor="let user of us.staff|search: searching: 'name'|sort: sort_type">
					<td data-label="Name" [tooltip]="user.name" tooltipClass="tableTooltip" tooltipShowDelay="200" (click)="menuTrigger.openMenu()">
						<div class="table__user" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="edit">
							<div class="table__user__img" [class._letter]="!user.avatarUrl">
								<picture [user]="user"></picture>
							</div>
							<div class="table__user__info">
								<button class="table__user__info-name" [attr.aria-label]="'Edit ' + user.firstName + user.lastName">{{user.firstName}} <wbr> {{user.lastName}}</button>
								<div class="table__user__info-desc">{{ts.by_author[user._id]|time:weekStart:(weekStart+(7*86400000)):ts.now}}</div>
							</div>
						</div>
					</td>
					<ng-container *ngFor="let add of x">
						<ng-container [ngSwitch]="dateWorkingHours[user._id+(weekStart+(add*86400000))]?.type">
							<!-- working hours -->
							<td *ngSwitchCase="'working'" 
								class="table__working-hours ovf-hidden"
								[attr.data-label]="(weekStart+(add*86400000))|date" 
								[attr.data-day]="(weekStart+(add*86400000))|date: 'EE'" 
								(click)="editWorkingHours(user, weekStart+(add*86400000));"
								[tooltip]="getTooltipWorkingHours(weekStart+(add*86400000), dateWorkingHours[user._id+(weekStart+(add*86400000))]?.text)" tooltipClass="tableTooltip" tooltipShowDelay="200" tooltipMobileDisabled="true"
								matRipple matRippleColor="#e9ebef99"
							>
								<span>
									<ng-container *ngFor="let hour of dateWorkingHours[user._id+(weekStart+(add*86400000))]?.hours; last as last">
										<span>{{hour}}</span>
										<wbr *ngIf="!last">
									</ng-container>
								</span>
							</td>

							<!-- closed or time off -->
							<td *ngSwitchCase="'off'" 
								[attr.data-label]="(weekStart+(add*86400000))|date"
								[attr.data-day]="(weekStart+(add*86400000))|date: 'EE'" 
								[tooltip]="getTooltipWorkingHours(weekStart+(add*86400000), dateWorkingHours[user._id+(weekStart+(add*86400000))]?.text)" tooltipClass="tableTooltip" tooltipShowDelay="200"
								class="table__working-hours _off ovf-hidden"
							>
								<span class="t-hide">
									{{dateWorkingHours[user._id+(weekStart+(add*86400000))]?.text}}
								</span>
							</td>

							<!-- add working hours -->
							<td *ngSwitchCase="'on'" 
								class="table__working-hours _add ovf-hidden"
								[attr.data-label]="(weekStart+(add*86400000))|date" 
								[attr.data-day]="(weekStart+(add*86400000))|date: 'EE'" 
								(click)="addWorkingHours(weekStart+(add*86400000), user);"
								[tooltip]="(weekStart+(add*86400000))|date: 'EE, MMM d, y'" tooltipClass="tableTooltip" tooltipShowDelay="200" tooltipMobileDisabled="true"
								matRipple matRippleColor="#e9ebef99"
								aria-label="Add"
							>
								<span [class.icon-plus]="!us.mobile">{{us.mobile ? 'On' : ''}}</span>
							</td>

							<td *ngSwitchDefault class="table__working-hours ovf-hidden"></td>
						</ng-container>
					</ng-container>
					<td>
						<div class="actions">
							<button mat-icon-button color="primary" tooltip="Edit" tooltipMobileDisabled="true" [attr.aria-label]="'Edit ' + user.firstName + user.lastName" class="waw-btn__icon primary mr10 _tablet" [matMenuTriggerFor]="edit">
								<span class="icon-pencil"></span>
							</button>
							<mat-menu #edit="matMenu" xPosition="after" [yPosition]="us.mobile ? 'above' : 'below'" backdropClass="menuBackdrop">
								<div class="matMenu__header" (click)="$event.stopPropagation()">
									<div class="matMenu__header-img" [class._letter]="!user.avatarUrl">
										<picture [user]="user" [big]="true"></picture>
									</div>
									<div class="matMenu__header-name">{{user.name}}</div>
								</div>
								<button class="matMenu__item" [disabled]="!(us.is.admin||us.is.owner||user._id == us._id)" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="editWorkingHours(user)">
									<span class="matMenu__item-icon icon-edit"></span>
									<span>Edit working hours</span>
								</button>
								<button class="matMenu__item" [disabled]="!(us.is.admin||us.is.owner||user._id == us._id)" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="editWorkingDays(user)">
									<span class="matMenu__item-icon icon-clock"></span>
									<span>Regular working hours</span>
								</button>
							</mat-menu>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</ng-container>

<!-- ===  Month view calendar === -->
<ng-container *ngIf="view == false">
	<!-- navigation by day of the month -->
	<ng-container *ngIf="us.mobile">
		<button mat-button class="month-nav-btn hideOnScroll" [matMenuTriggerFor]="navMenu" #navMenuTrigger="matMenuTrigger" (menuOpened)="checkElementsInView()" aria-label="Opening the navigation menu by day of the month">{{months[month] | slice:0:3 | uppercase}}</button>
		<mat-menu #navMenu="matMenu" xPosition="before" yPosition="above" backdropClass="menuBackdrop_small">
			<div class="month-nav">
				<div class="month-nav__date">{{months[month]}} {{year}}</div>
				<div class="month-nav__calendar">
					<div class="month-nav__calendar__day fl-c-c" *ngFor="let day of weekDays">{{day | slice:0:1}}</div>
					<ng-container *ngFor="let week of ui.arr(dates.length/7); index as i">
						<ng-container *ngFor="let date of ui.arr(7); index as k">
							<div class="month-nav__calendar__date fl-c-c" [class._active]="monthDate == dates[i*7 + k]?.time" [class._disabled]="dates[i*7 + k]?.month != '_this'" (click)="dates[i*7 + k]?.month == '_this' ? scrollTo(dates[i*7 + k]?.time) : ''" matRipple matRippleColor="#4444441a" matRippleCentered="true" [matRippleDisabled]="dates[i*7 + k]?.month != '_this'">{{dates[i*7 + k]?.month == '_this' ? dates[i*7 + k]?.date : ''}}</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</mat-menu>
	</ng-container>

	<div class="table-container month">
		<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
			<thead>
				<tr>
					<th *ngFor="let day of weekDays">{{day}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let week of weeks; index as i">
					<td *ngFor="let day of weekDays; index as k" #monthDay [attr.data-date]="dates[i*7 + k]?.time" [class._not-this-month]="dates[i*7 + k]?.month != '_this'" [class.this-month]="dates[i*7 + k]?.month == '_this'" cdkOverlayOrigin #dayAll="cdkOverlayOrigin">
						<div class="day" *ngIf="dates[i*7 + k]?.month == '_this'">
							<div class="day__header" (click)="us.mobile ? show_date[dates[i*7 + k]?.time] = !show_date[dates[i*7 + k]?.time] : ''">
								<div class="day__header-date" [attr.data-day]="dates[i*7 + k]?.time|date: 'EE'">
									{{!us.mobile ? (dates[i*7 + k]?.date) : (dates[i*7 + k]?.time|date)}}
								</div>
								<button mat-icon-button *ngIf="!us.mobile" class="day__header-menu-btn" tooltip="Actions" [attr.aria-label]="'Action menu for ' + (dates[i*7 + k]?.time|date)" [matMenuTriggerFor]="dateMenu" #dateMenuTrigger="matMenuTrigger" [ngStyle]="{'opacity': dateMenuTrigger.menuOpen ? 1 : ''}"><span class="material-icons">more_vert</span></button>
								<button mat-icon-button color="primary" *ngIf="us.mobile" class="day__header-open-btn" [ngStyle]="{'transform': show_date[dates[i*7 + k]?.time] ? 'rotate(-180deg)' : 'rotate(0deg)' }" [attr.aria-label]="'Show all employees on ' + (dates[i*7 + k]?.time|date)"><span class="icon-arrow_down"></span></button>
								<mat-menu #dateMenu="matMenu" [xPosition]="k == 0 ? 'after' : 'before'" yPosition="below" backdropClass="menuBackdrop">
									<button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="addWorkingHours(dates[i*7 + k]?.time)">
										<span>Add Working hours</span>
									</button>
									<button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="show_all = dates[i*7 + k]?.time; show_more = null">
										<span>Show all employees</span>
									</button>
								</mat-menu>
								<div class="day__header-staff" *ngIf="us.mobile && !show_date[dates[i*7 + k]?.time]">
									<div class="day__header-staff__img" *ngFor="let user of (dateWorkingUsers[dates[i*7 + k]?.time]|search: searching: 'name')">
										<picture [user]="user"></picture>
									</div>
									<ng-container *ngIf="!(dateWorkingUsers[dates[i*7 + k]?.time]|search: searching: 'name')?.length">
										No available employees
									</ng-container>
								</div>
							</div>
							<div class="day__staff-list" *ngIf="!us.mobile || show_date[dates[i*7 + k]?.time]" [@height]="show_date[dates[i*7 + k]?.time]">
								<ng-container *ngFor="let user of !us.mobile ? (dateWorkingUsers[dates[i*7 + k]?.time]|search: searching: 'name') : (us.staff|search: searching: 'name'); index as j">
									<ng-container *ngIf="j<number_of_employees_show || us.mobile">
										<ng-container
											*ngTemplateOutlet="userTimeRange; context: {$implicit: user, time: dates[i*7 + k]?.time, hours: !us.mobile ? false : true}"
										></ng-container>
									</ng-container>
								</ng-container>
								<ng-container *ngIf="(dateWorkingUsers[dates[i*7 + k]?.time]|search: searching: 'name')?.length > number_of_employees_show && !us.mobile">
									<button mat-button color="primary" [attr.aria-label]="'Show working hours of all employees on ' + (dates[i*7 + k]?.time|date)" class="day__showAll" (click)="show_more = dates[i*7 + k]?.time; show_all = null">
										+ {{(dateWorkingUsers[dates[i*7 + k]?.time]|search: searching: 'name')?.length - number_of_employees_show }} more
									</button>
								</ng-container>
							</div>

							<ng-template
								cdkConnectedOverlay
								[cdkConnectedOverlayOrigin]="dayAll"
								[cdkConnectedOverlayOpen]="show_more == dates[i*7 + k]?.time && !us.mobile || show_all == dates[i*7 + k]?.time && !us.mobile"
								[cdkConnectedOverlayHasBackdrop]="true"
								[cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
								[cdkConnectedOverlayPanelClass]="'panel' + dates[i*7 + k]?.time"
								[cdkConnectedOverlayPositions]="positions"
								[cdkConnectedOverlayFlexibleDimensions]="true"
								[cdkConnectedOverlayViewportMargin]="10"
								(backdropClick)="show_more = null; show_all = null"
								(detach)="show_more = null; show_all = null"
								(positionChange)="positionCheck()"
							>
								<div class="all-items">
									<div class="all-items__date">{{(show_more|date) || (show_all|date)}}</div>
									<div class="all-items__day">{{(show_more|date: 'EE') || (show_all|date: 'EE')}}</div>
									<div class="all-items__list">
										<ng-container *ngFor="let user of show_more ? (us.staff|search: searching: 'name'|workingUsers: dates[i*7 + k]?.time:us.now) : (us.staff|search: searching: 'name')">
											<ng-container
												*ngTemplateOutlet="userTimeRange; context: {$implicit: user, time: dates[i*7 + k]?.time, disabledTooltip: true, hours: true}"
											></ng-container>
										</ng-container>
									</div>
								</div>
							</ng-template>

							<ng-template #userTimeRange let-user let-time="time" let-disabledTooltip="disabledTooltip" let-hours="hours">
								<div class="userTime table__user"
									[tooltip]="!disabledTooltip && !us.mobile ? getTooltipWorkingHours(time, dateWorkingHours[user._id+time]?.text, user.name) : ''" 
									tooltipShowDelay="200" tooltipMobileDisabled="true"
									matRipple matRippleColor="#4444441a"
									[matMenuTriggerFor]="userMenu"
								>
									<div class="userTime__img table__user__img" [class._letter]="!user.avatarUrl">
										<picture [user]="user"></picture>
									</div>
									<div class="userTime__info table__user__info">
										<span class="userTime__info-name table__user__info-name text-elipsis">{{user.firstName}} <wbr> {{user.lastName}}</span>

										<div class="userTime__info-desc table__user__info-desc text-elipsis" *ngIf="!hours || dateWorkingHours[user._id+time]?.type != 'working'">{{dateWorkingHours[user._id+time]?.text}}</div>

										<div class="userTime__info-desc table__user__info-desc text-elipsis" *ngIf="hours && dateWorkingHours[user._id+time]?.type == 'working'">
											<ng-container *ngFor="let hour of dateWorkingHours[user._id+time]?.hours; last as last">
												<span>{{hour}}</span>
												<wbr *ngIf="!last">
											</ng-container>
										</div>
									</div>
								</div>
								<mat-menu #userMenu="matMenu" [xPosition]="us.mobile ? 'before' : 'after'" [yPosition]="us.mobile ? 'above' : 'below'" class="m0" backdropClass="menuBackdrop">
									<div (click)="show_more = null; show_all = null">
										<div class="matMenu__header" (click)="$event.stopPropagation()">
											<div class="matMenu__header-img" [class._letter]="!user.avatarUrl">
												<picture [user]="user" [big]="true"></picture>
											</div>
											<div class="matMenu__header-name">{{user.name}}</div>
											<div class="matMenu__header-date">{{time|date}}</div>
										</div>
										<ng-container *ngIf="dateWorkingHours[user._id+time]?.type == 'working'">
											<button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="editWorkingHours(user, time)">
												<span class="matMenu__item-icon material-icons-round">edit</span>
												<span>Edit this day</span>
											</button>
										</ng-container>
										<ng-container *ngIf="dateWorkingHours[user._id+time]?.type == 'on'">
											<button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="addWorkingHours(time, user)">
												<span class="matMenu__item-icon icon-plus"></span>
												<span>Add working hours</span>
											</button>
										</ng-container>
										<!-- <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="editWorkingHours(user)">
											<span class="matMenu__item-icon icon-clock"></span>
											<span>Edit working hours</span>
										</button> -->
										<button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="editWorkingDays(user)">
											<span class="matMenu__item-icon icon-clock"></span>
											<span>Regular working hours</span>
										</button>
									</div>
								</mat-menu>
							</ng-template>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</ng-container>
