import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GroupService, LoaderService, LocationService, ProductService, RoomService, ServiceService, TaxService, UserService } from '@services';
import { AlertService, ModalService } from 'wacom';
@Component({
	selector: 'service',
	templateUrl: './service.component.html',
	styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit, AfterContentChecked{
	public new_service:any = {};
	public close;
	public colorSelect: any;
	public groupId;
	public submitted: Boolean;
	public width: number = 290;
	@ViewChild('select', { static: false }) select_container: ElementRef;
	constructor(public modal: ModalService,
		public ss: ServiceService,
		public us: UserService,
		public ps: ProductService,
		public ls: LocationService,
		public rs: RoomService,
		public alert: AlertService,
		public ts: TaxService,
		private loader: LoaderService,
		public gs: GroupService) {}
	ngOnInit() {
		if(!this.new_service.group) this.new_service.group = this.groupId
		if(!this.new_service.color) this.new_service.color = this.us.randomColor;
		if(!this.new_service.locations?.length && this.ls._locations.primary && this.ls._locations.primary.length) {
			this.new_service.locations = [this.ls._locations.primary[0]._id];
		}
	}
	ngAfterContentChecked() {
		if(this.select_container) this.width = this.select_container.nativeElement.offsetWidth;
	}
	deleteService(service) {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete service',
			body: `Are you sure you want to delete service ${service.name}?`,
			on_add: ()=> {
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));
				this.ss.delete(service, () => {
					this.loader.remove();
					service={};
					this.close();
				});
			}
		});
	}
	save() {
		this.submitted = true;
		if(!this.new_service.name||!this.new_service.staffs||this.new_service.staffs&&!this.new_service.staffs.length||!this.new_service.group||!this.new_service.locations||this.new_service.locations&&!this.new_service.locations.length||!this.new_service.price_type||!this.new_service.price||!this.new_service.time||(this.rs.rooms.length && this.new_service.locations.length==1 && this.rs.by_locations[this.new_service.locations[0]].length)&&!this.new_service.room||(this.rs.rooms.length && (this.new_service.locations.length>1 || !this.new_service.locations.length))&&!this.new_service.room) return this.alert.error({
			text: "Please fill required fields",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.ss.create(this.new_service, () => {
			this.loader.remove();
			this.close();
		});
	}
	selectAll() {
		this.new_service.staffs = this.us.staff.map(item => item._id);
	}
	clearAll() {
		this.new_service.staffs = [];
	}
}
