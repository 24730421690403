import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { BrandService, LoaderService, UserService } from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-res-brands',
	templateUrl: './res-brands.component.html',
	styleUrls: ['./res-brands.component.scss']
})
export class ResBrandsComponent implements OnInit, AfterViewInit {
	public allImagesLoaded = new BehaviorSubject <boolean>(false);
	constructor(public modal: ModalService, public bs: BrandService, public us: UserService, private route: ActivatedRoute,
				private router: Router,
				private loader: LoaderService,
				private mongo: MongoService,
				private eref: ElementRef) {}
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
	}
	ngAfterViewInit() {
		this.mongo.on('brand', () => {
			this.removeLoader();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(profile = null){
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'modal',
				profile: profile ? JSON.parse(JSON.stringify(profile)) : {},
				title: 'Brand',
				components: [{
					type: 'INPUT',
					input: 'name',
					label: 'Brand name*',
					placeholder: 'e.g. Sport Life',
					reqText: 'Name is required',
					required: true,
				}],
				alertOpts: {
					title: 'Delete brand',
					body: `Are you sure you want to delete brand ${profile?.name}?`
				},
				onDelete: (profile: any) => {
					this.bs.delete(profile, () => {
						this.modal.destroy();
					});
				},
				onSave: (profile: any) => {
					this.bs.create(profile, () => {
						this.modal.destroy();
					});
				}
			});	
		}	
	}
	delete(profile) {
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'deleteAlert',
				title: 'Delete brand',
				body: `Are you sure you want to delete brand ${profile.name}?`,
				on_add: ()=> {
					this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerBox'));
					this.bs.delete(profile, () => {
						this.removeLoader();
					});
				}
			});
		}
	}
	removeLoader() {
		if(!this.bs.brands?.length) {
			this.allImagesLoaded.subscribe((state: boolean) => {
				if(state === true) {
					this.loader.remove();
				}
			});
		} else {
			this.loader.remove();
		}
	}
}
