import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { UserService, QuestionService, LoaderService } from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-questionnaire',
	templateUrl: './questionnaire.component.html',
	styleUrls: ['./questionnaire.component.scss', '../setup.component.scss']
})
export class QuestionnaireComponent implements OnInit, AfterViewInit{	
	public allImagesLoaded = new BehaviorSubject <boolean>(false);
	edit(profile){
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({component: 'question', profile: JSON.parse(JSON.stringify(profile))});
		}
	}
	constructor(public modal: ModalService, public us: UserService,
		public qs: QuestionService,
		public router: Router,
		private route: ActivatedRoute,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Questionnaire';
		this.us.headerSubtitle = 'Setup';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('question', () => {
			if(!this.qs.questions?.length) {
				this.allImagesLoaded.subscribe((state: boolean) => {
					if(state === true) {
						this.loader.remove();
					}
				});
			} else {
				this.loader.remove();
			}
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openQuestion(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({ component: 'question'})		
	}
}
