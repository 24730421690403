<div class="containerBox setup">
	<div class="setup-empty setup-box" *ngIf="!ass.alerts?.length">
		<div class="setup-empty__img"><img src="assets/img/setup/alerts.png" (load)="allImagesLoaded.next(true)" alt="alerts" draggable="false"/></div>
		<div class="setup-empty__title">Please add an alert for your client, so your employees pay closer attention</div>
		<div class="setup-empty__btn">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add alert</span>
			</button>
		</div>
	</div>
	<div class="setup-container" *ngIf="ass.alerts?.length">
		<div class="setup-item setup-box" *ngFor="let alert of ass.alerts">
			<div class="setup-item__title">{{us._users[alert.client]?.name}}</div>
			<div class="setup-item__actions">
				<button mat-icon-button color="primary" class="action-edit" (click)="edit(alert);"><span class="icon-edit"></span></button>
				<button mat-icon-button color="warn" class="action-delete" (click)="delete(alert);"><span class="icon-delete"></span></button>
			</div>
			<hr>
			<div class="setup-item__property">
				<div class="setup-item__property__name mr0">
					<div class="note _limit" #Note [innerHtml]="alert.description | note" [class._more]="Note.scrollHeight > Note.clientHeight"></div>
					<div class="note-btn">
						<button mat-icon-button type="button" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')" color="primary" aria-label="More"><span [class.icon-arrow_down]="Note.scrollHeight > Note.clientHeight" [class.icon-arrow_up]="!(Note.scrollHeight > Note.clientHeight)"></span></button>
					</div>
				</div>
			</div>
		</div>
		<div class="setup-add">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add alert</span>
			</button>
		</div>
	</div>
</div>