import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { ProductService, BrandService, CategoryService, DiscountService, TaxService, UserService, LoaderService } from '@services';
import { FileService, ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';
import { ProductComponent } from 'src/app/modals/product/product.component';
import { BehaviorSubject } from 'rxjs';


@Component({
	selector: 'app-res-products',
	templateUrl: './res-products.component.html',
	styleUrls: ['./res-products.component.scss']
})
export class ResProductsComponent implements OnInit, AfterViewInit {
	constructor(public modal: ModalService,
		public ts: TaxService,
		public ds: DiscountService,
		public bs: BrandService,
		public cs: CategoryService,
		public fs: FileService,
		public us: UserService,
		public ps: ProductService,
		private route: ActivatedRoute,
		private router: Router,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef ){}
	public sort_type:any = {};
	public allImagesLoaded = new BehaviorSubject <boolean>(false);
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
	}
	ngAfterViewInit() {
		this.mongo.on('tax discount brand category product', () => {
			this.removeLoader();
		});
	}
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	edit(product) {
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({component: 'product', new_product: JSON.parse(JSON.stringify(product))})
		}
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	delete(product) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete product',
			body: `Are you sure you want to delete product ${product.name}`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerBox'));
				this.ps.delete(product, () => {
					this.removeLoader();
				});
			}
		});
	}
	removeLoader() {
		if(!this.ps.products?.length) {
			this.allImagesLoaded.subscribe((state: boolean) => {
				if(state === true) {
					this.loader.remove();
				}
			});
		} else {
			this.loader.remove();
		}
	}
}
