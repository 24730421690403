<div class="containerBox" [class.setup]="(!group_id && !gs.groups.length) || (group_id && !ss.group[group_id]?.length)">
    <div class="setup-empty setup-box" *ngIf="(!group_id && !gs.groups.length) || (group_id && !ss.group[group_id]?.length)">
        <div class="setup-empty__img"><img src="assets/img/setup/services.png" (load)="allImagesLoaded.next(true)" alt="services" draggable="false"/></div>
        <ng-container *ngIf="!group_id">
            <div class="setup-empty__title">Please add a service group</div>
            <div class="setup-empty__btn">
                <button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
                    <span class="icon-plus"></span>
                    <span>Add group</span>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="group_id">
            <div class="setup-empty__title">This group doesn't have any services yet</div>
            <div class="setup-empty__btn">
                <button mat-flat-button color="primary" class="waw-btn _primary" (click)="create(group_id)">
                    <span class="icon-plus"></span>
                    <span>Add service</span>
                </button>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="(!group_id && gs.groups.length) || (group_id && ss.group[group_id]?.length)">
        <div class="navigation">
            <div class="navigation__title" [class.group]="group_id">
                <span>Services</span>
                <ng-container *ngIf="group_id">
                    <span>/</span>
                    <span class="t-hide">{{gs._groups[group_id]?.name}}</span>
                </ng-container>
                <div class="navigation__topbar display-sm" *ngIf="!group_id">
                    <button mat-button color="primary" type="button" class="topbar-user-btn waw-btn _second display-sm" (click)="create()">
                        <span class="icon-plus"></span>
                        <span>New service</span>
                    </button>
                </div>
            </div>
            
            <ng-container *ngIf="!group_id">
                <div class="navigation__btn">
                    <button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
                        <span class="icon-plus"></span>
                        <span>New group</span>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="group_id">
                <div class="navigation__btns">
                    <button mat-flat-button class="waw-btn _second" type="button" (click)="openModal(gs._groups[group_id])">
                        <span class="icon-pencil"></span>
                        <span>Edit</span>
                    </button>
                    <button mat-flat-button class="waw-btn _second" type="button" (click)="delete(gs._groups[group_id])">
                        <span class="icon-delete"></span>
                        <span>Delete group</span>
                    </button>
                    <div class="navigation__btn">
                        <button mat-flat-button color="primary" type="button" class="waw-btn _primary" (click)="create(group_id)">
                            <span class="icon-plus"></span>
                            <span>Add service</span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    
        <ng-container *ngIf="!group_id">
            <div class="groups">
                <div class="group" *ngFor="let group of gs.groups">
                    <div class="group__title">
                        <div class="group__title-name t-hide">{{group.name}} <ng-container *ngIf="ss.group[group._id]?.length">({{ss.group[group._id]?.length || 0}})</ng-container> </div>
                        <button mat-icon-button (click)="openModal(group)" type="button" class="group__title-action edit" cdkOverlayOrigin #ntf="cdkOverlayOrigin" tooltip="Edit" aria-label="Edit"><span class="icon-edit"></span></button>
                        <button mat-icon-button (click)="delete(group)" type="button" class="group__title-action delete" cdkOverlayOrigin #ntf="cdkOverlayOrigin" tooltip="Delete" aria-label="Delete"><span class="icon-delete"></span></button>
                        <a mat-icon-button [routerLink]="'/services/'+group._id" class="group__title-action open" cdkOverlayOrigin #ntf="cdkOverlayOrigin" tooltip="Open" aria-label="Open"><span class="icon-arrow_right"></span></a>
                    </div>
                    <div class="group__body">
                        <div class="group__body-service empty fl-column-ai-c jc-c" *ngIf="!ss.group[group._id]?.length">
                            <div class="empty__title">No services</div>
                            <div>This group doesn't have any services yet</div>
                            <button mat-button color="primary" type="button" class="topbar-user-btn waw-btn _second" (click)="create(group._id)">
                                <span class="icon-plus"></span>
                                <span>Add service</span>
                            </button>
                        </div>
                        <div class="group__body-service service" *ngFor="let service of ss.group[group._id] | slice: 0: 3" [class._two]="ss.group[group._id]?.length == 2" [class._over_three]="ss.group[group._id]?.length >= 3" [routerLink]="'/services/'+group._id">
                            <div class="service__title">
                                <div class="service__title-color" [ngStyle]="{ 'background-color': service.color }"></div>
                                <div class="service__title-name t-hide">{{service.name}}</div>
                            </div>
                            <div class="service__body">
                                <div data-label="Duration" class="service__body-item text-elipsis">{{service.time | formatTime}}</div>
                                <div data-label="Price" class="service__body-item text-elipsis">{{ service.price | currency }}</div>
                                <div data-label="Capacity" class="service__body-item text-elipsis">{{ service.capacity ? ( service.capacity + ' people') : '' }}</div>
                                <div data-label="Location" class="service__body-item ovf-hidden">
                                    <div class="list">
                                        <ng-container *ngFor="let location of getLocations(service.locations); index as i">
                                            <div class="list__item text-elipsis" *ngIf="i < 1 || ( i == 1 && getLocations(service.locations)?.length == 2 )">{{ls._locations[location]?.name}}</div>
                                        </ng-container>
                                        <ng-container *ngIf="getLocations(service.locations)?.length > 2">
                                            <div class="list__more text-elipsis">+ {{getLocations(service.locations)?.length - 1}} more</div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div data-label="Room" class="service__body-item text-elipsis">{{rs._rooms[service.room]?.name}}</div>
                                <div data-label="Staff" class="service__body-item ovf-hidden">
                                    <div class="list staff">
                                        <ng-container *ngFor="let staff of getStaff(service.staffs); index as i">
                                            <div class="list__item text-elipsis" *ngIf="i < 2 || ( i == 2 && getStaff(service.staffs)?.length == 3 )">{{us._users[staff]?.name}}</div>
                                        </ng-container>
                                        <ng-container *ngIf="getStaff(service.staffs)?.length > 3">
                                            <div class="list__more text-elipsis">+ {{getStaff(service.staffs)?.length - 2}} more</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    
        <ng-container *ngIf="group_id">
            <div class="services">
                <div class="service" *ngFor="let service of ss.group[group_id]" (click)="editService(service);">
                    <div class="service__title">
                        <div class="service__title-color" [ngStyle]="{ 'background-color': service.color }"></div>
                        <div class="service__title-name t-hide">{{service.name}}</div>
                    </div>
                    <div class="service__body">
                        <div data-label="Duration" class="service__body-item text-elipsis">{{service.time | formatTime}}</div>
                        <div data-label="Price" class="service__body-item text-elipsis">{{ service.price | currency }}</div>
                        <div data-label="Capacity" class="service__body-item text-elipsis">{{ service.capacity ? ( service.capacity + ' people') : '' }}</div>
                        <div data-label="Location" class="service__body-item ovf-hidden">
                            <div class="list">
                                <ng-container *ngFor="let location of getLocations(service.locations); index as i">
                                    <div class="list__item text-elipsis" *ngIf="i < 1 || ( i == 1 && getLocations(service.locations)?.length == 2 )">{{ls._locations[location]?.name}}</div>
                                </ng-container>
                                <ng-container *ngIf="getLocations(service.locations)?.length > 2">
                                    <div class="list__more text-elipsis">+ {{getLocations(service.locations)?.length - 1}} more</div>
                                </ng-container>
                            </div>
                        </div>
                        <div data-label="Room" class="service__body-item text-elipsis">{{rs._rooms[service.room]?.name}}</div>
                        <div data-label="Staff" class="service__body-item ovf-hidden">
                            <div class="list staff">
                                <ng-container *ngFor="let staff of getStaff(service.staffs); index as i">
                                    <div class="list__item text-elipsis" *ngIf="i < 2 || ( i == 2 && getStaff(service.staffs)?.length == 3 )">{{us._users[staff]?.name}}</div>
                                </ng-container>
                                <ng-container *ngIf="getStaff(service.staffs)?.length > 3">
                                    <div class="list__more text-elipsis">+ {{getStaff(service.staffs)?.length - 2}} more</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>