<div class="modal-box">
    <div class="modal-title">{{ type == 'allbookings' ? 'All Bookings' : '' }} {{ type == 'canceledbookings' ? 'Canceled' : '' }} {{ type == 'completedbookings' ? 'Completed' : '' }}</div>
    <button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
    <app-table [columns]="columns" [rows]="rows">
        <ng-template sort cell="appointment" let-element>
            {{element?.appointment}}
        </ng-template>
        <ng-template sort cell="date" let-element>
            {{element?.date|date:'short'}}
        </ng-template>
        <ng-template sort cell="duration" let-element>
            {{element?.duration | formatTime}}
        </ng-template>
        <ng-template sort cell="price" let-element>
            ${{element?.price}}
        </ng-template>
        <ng-template sort cell="status" let-element>
            {{element?.status}}
        </ng-template>
        <ng-template cell="notes" let-element>
            <div style="text-align: left;">
                <div class="note _limit" #Note [innerHtml]="element?.notes | note" [class._more]="Note.scrollHeight > Note.clientHeight"></div>
                <div class="note-btn">
                    <button mat-icon-button type="button" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')" color="primary" aria-label="More"><span [class.icon-arrow_down]="Note.scrollHeight > Note.clientHeight" [class.icon-arrow_up]="!(Note.scrollHeight > Note.clientHeight)"></span></button>
                </div>
            </div>
        </ng-template>
    </app-table>
</div>
