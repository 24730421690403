import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { UserService, ReasonService, LoaderService } from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-cancel-reason',
	templateUrl: './cancel-reason.component.html',
	styleUrls: ['./cancel-reason.component.scss', '../setup.component.scss']
})
export class CancelReasonComponent implements OnInit, AfterViewInit {
	public allImagesLoaded = new BehaviorSubject <boolean>(false);
	constructor(public modal: ModalService,
		public rs: ReasonService,
		public us: UserService,
		public router: Router,
		private route: ActivatedRoute,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Cancellation reasons';
		this.us.headerSubtitle = 'Setup';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('reason', () => {
			this.removeLoader();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(profile = null){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'modal',
			profile: profile ? JSON.parse(JSON.stringify(profile)) : {},
			title: 'Reason',
			components: [{
				type: 'INPUT',
				input: 'name',
				label: 'Reason name*',
				placeholder: 'e.g. Client unavaible',
				reqText: 'Name is required',
				required: true,
			}],
			alertOpts: {
				title: 'Delete reason',
				body: `Are you sure you want to delete reason ${profile?.name}?`
			},
			onDelete: (profile: any) => {
				this.rs.delete(profile, () => {
					this.modal.destroy();
				});
			},
			onSave: (profile: any) => {
				this.rs.create(profile, () => {
					this.modal.destroy();
				});
			}
		});	
	}
	delete(reason) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete reason',
			body: `Are you sure you want to delete reason ${reason.name}?`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.rs.delete(reason, () => {
					this.removeLoader();
				});
			}
		});
	}
	removeLoader() {
		if(!this.rs.reasons?.length) {
			this.allImagesLoaded.subscribe((state: boolean) => {
				if(state === true) {
					this.loader.remove();
				}
			});
		} else {
			this.loader.remove();
		}
	}
}
